import api from './api'

export const getCheckProfile = async (token, data) => {
  return await api.post(`/api/gomo/check/profile`, data, {
    headers: {
      'content-type': 'application/json',
      authorization: token,
    },
  })
}

export const getCustomerIm = async (data) => {
  return await api.post(`/api/gomo/self-service/customer-im`, data)
}

export const getLocation = async (data) => {
  return await api.post(`/api/gomo/self-service/location`, data)
}

export const createInternetProblem = async (token, data) => {
  return await api.post(`/api/gomo/self-service/problem/internet`, data, {
    headers: {
      'content-type': 'application/json',
      authorization: token,
    },
  })
}

export const createVoiceProblem = async (token, data) => {
  return await api.post(`/api/gomo/self-service/problem/voice`, data, {
    headers: {
      'content-type': 'application/json',
      authorization: token,
    },
  })
}

export const createKyc = async (token, formData) => {
  return await api.post(`/api/gomo/self-service/kyc`, formData, {
    headers: {
      'content-type': 'multipart/form-data', 
      authorization: token,
    },
  })
}

export const createBcWc = async (token, formData) => {
  return await api.post(`/api/gomo/self-service/bcwc`, formData, {
    headers: {
      'content-type': 'multipart/form-data', 
      authorization: token,
    },
  })
}
