import styled from 'styled-components'


export const StyledWrapForm = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1.5em;
  flex-direction: column;
  overflow: auto;
  @media only screen and (max-width: 480px) {
    padding: 1em;
}
`

export const StyledHeader= styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 1em;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 1em;
  flex-direction: column;
`



export const StyledTextHeader = styled.div`
display: flex;
font-size: 26px;
font-weight: bold;
justify-content: center;
color: #e7279b;
margin-bottom: 0.5em;
`


export const StyledTextSubHeader = styled.div`
display: flex;
font-size: 21px;
word-wrap: break-word;
word-break: keep-all;
`

export const StyledTextLabel = styled.div`
  display: flex;
  font-size: 21px;
`

export const StyledTextTopic = styled.div`
  display: flex;
  font-size: 21px;
  font-weight: bold;
  margin-top: 0.25em;
  margin-bottom: 0.1em;
`

export const StyledWrapButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`





