import styled from 'styled-components'

export const StyledWrapModal = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  font-size: 22px;
  line-height: 1.3;
  overflow-wrap: anywhere;
  bottom: 0px;
`

export const StyledModal = styled.div`
  display: inline-block;
  background-color: #fff;
  width: 60%;
  height: auto;
  max-height: 550px;
  z-index: 9999;
  position: absolute;
  border: 1px solid #e7279b;
  border-radius: 10px;
  justify-content: center;
  overflow: auto;
  top: 15%;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 512px) {
    width: 90%;
    top: 10%;
}   
`


export const StyledWrapWebview = styled.div`
  background-color: #fff;
  width: 60%;
  height: auto;
  border: 1px solid #e7279b;
  border-radius: 10px;
  justify-content: center;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 512px) {
    width: 90%;
}
`

