import axios from 'axios'
import { baseUrl } from '../functions/baseUrl'
const isWebview = require('is-ua-webview')

const axiosService = axios.create({
  timeout: 1200000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-request-channel' : isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb'
  },
  withCredentials: true
})

axiosService.interceptors.request.use(
  (request) => {
    return request
  },
  (error) => {
    return error
  }
)

axiosService.interceptors.response.use(
  (response) => {
    return onSuccess(response)
  },
  (error) => {
    return onError(error)
  }
)

const onSuccess = (response) => {
  return response.data
}

const onError = (error) => {
  return error.response
}

const service = () => {
  const subChannel =  isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb'
  return {
    get: async (url) => {
      if (url.includes('?')) {
        if (url.includes('subChannel')) {
          return await axiosService.get(`${baseUrl()}${url}`)
        } else {
          return await axiosService.get(`${baseUrl()}${url}&subChannel=${subChannel}`)
        }
      } else {
        return await axiosService.get(`${baseUrl()}${url}?subChannel=${subChannel}`)
      }
    },
    post: async (url, params, headers) => {
      if (url.includes('subChannel')) {
        return await axiosService.post(`${baseUrl()}${url}`, params,headers)
      } else {
        return await axiosService.post(`${baseUrl()}${url}?subChannel=${subChannel}`, params,headers)
      }
    },
    patch: async (url, params) => {
      return await axiosService.patch(`${baseUrl()}${url}?subChannel=${subChannel}`, params)
    },
    delete: async (url, params) => {
      return await axiosService.delete(`${baseUrl()}${url}?subChannel=${subChannel}`, params)
    },
  }
}

const api = service()
export default api
