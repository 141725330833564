import api from './api'


export const getSurvey = async (userId, surveyCode,subChannel) => {
  if (subChannel) {
    return  await api.get(`/api/gomo/survey?userId=${userId}&surveyCode=${surveyCode}&subChannel=${subChannel}`)
  } else {
    return  await api.get(`/api/gomo/survey?userId=${userId}&surveyCode=${surveyCode}`)
  }
}


export const updateSurvey = async (data, token, subChannel) => {
  if (subChannel) {
    return  await api.post(`/api/gomo/survey?subChannel=${subChannel}`, data, {
      headers: {
        'content-type': 'application/json',
        authorization: token,
      },
    })
  } else {
    return  await api.post(`/api/gomo/survey`, data, {
      headers: {
        'content-type': 'application/json',
        authorization: token,
      },
    })
  }
}

