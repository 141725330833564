import React, { Component } from 'react'
import { Input, Image } from 'antd'
import { StyledWrapModal, StyledModal, StyledText, StyledWrapImage, StyledWrapIcon, StyledWrapError } from './style'
import { CloseCircleOutlined } from '@ant-design/icons'
import {getImageSrc} from '../../functions/getImageSrc'

export default class ModalGomo extends Component {
    constructor(props) {
        super(props)
        this.state = {
          mobileNo: null,
          error: false
        }
      }

  componentDidMount = () => {
    document.body.classList.add("gomo-overflow-hiden");
  }


  render() {
    const props = this.props
    return (
      <StyledWrapModal>
        <div className="modal-gomo">
          <StyledModal className="ie-modal-gomo">
            <StyledWrapIcon>
              <CloseCircleOutlined style={{ fontSize: '20px', color: '#e4238f' }} onClick={() => props.closeModal()} />
            </StyledWrapIcon>
            <StyledText>ช่วยพิมพ์เบอร์ GOMO ให้เราหน่อยนะคะ</StyledText>
            <Input.Search
             onChange={ (e) => {
                const mobileNo = e.target.value;
                const re = /^[0-9\b]+$/;
                if (mobileNo === '' || re.test(mobileNo)) {
                  this.setState({ mobileNo: e.target.value });
                } 
              }}
            maxLength={10}
              placeholder="Ex.0812345678"
              allowClear
              enterButton="GO!"
              size="large"
              onSearch={(e) => {
                if (e.length === 10) {
                  document.body.className = document.body.className.replace("gomo-overflow-hiden","");
                  props.onSubmit(e)
                } else {
                  this.setState({ error: true });
                }
               
              }}
              value={this.state.mobileNo}
            />

            {this.state.error && 
            <StyledWrapError>
            <div>** เราแนะนำให้พิมพ์เบอร์ GOMO 10 หลัก หรือเบอร์ติดต่ออื่นๆ เท่านั้น </div>
            <div>เช่น 0812345678 พิมพ์แล้วเราจะรีบไปหาข้อมูล และตอบกลับในช่องแชทนี้ทันทีค่ะ</div> 
            </StyledWrapError>
            }

            <StyledWrapImage>
              <Image
                width={'100%'}
                preview={false}
                src={getImageSrc('image/gomo_bg.png')}
              />
            </StyledWrapImage>
          </StyledModal>
        </div>
      </StyledWrapModal>
    )
  }
}
