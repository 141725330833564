import styled from 'styled-components'



export const StyledWrapSuggestion = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  position: absolute;
  bottom: 3em;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  padding: 0em 0.5em;
  background: linear-gradient(360deg, rgba(241,241,255,1) 0%, rgba(255,255,255,0) 100%);

`

export const StyledSuggestionItem = styled.div`
  display: inline-block;
  background-color: #fff;
  margin: 0.5em 0.25em;
  padding: 0.4em 0.7em;
  border-radius: 1em;
  font-size: 19px;
  cursor: pointer;
  color: ${props => props.blockQuickReply && 'gray'};
`

