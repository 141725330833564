import styled from 'styled-components'


export const StyledWrapModal = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 100;
    position: fixed;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    font-size: 22px;
    line-height: 1.3;
    overflow-wrap: anywhere;
    bottom: 0px;
`

export const StyledModal = styled.div`
  display: inline-block;
  background-color: #fff;
  width: 60%;
  height: auto;
  z-index: 100;
    position: absolute;
    border: 1px solid #e7279b;
    border-radius: 10px;
    justify-content: center;
    top : 1em;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    max-width: 18em;
`


export const StyledText = styled.div`
  display: flex;
  font-size: 28px;
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  justify-content: center;

  @media only screen and (max-width: 480px) {
    font-size: 24px;
}
`

export const StyledWrapImage = styled.div`
  display: flex;
  margin-top: 1em;
  justify-content: center;
  width: 100%;
`


export const StyledWrapIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const StyledWrapError = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  font-size: 20px;
  color: #ff0000;
  margin: 0.5em 0em;
`