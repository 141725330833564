import styled from 'styled-components'

export const StyledWrapModal = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 100;
  position: fixed;
  bottom: 0px;
  overflow: scroll;
    overflow-x: hidden;
  flex-direction: column;
`

export const StyledClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  align-self: flex-end;
  margin: 1em 1em 0em 0em;
  cursor: pointer;
`
