import React from 'react'
import ReactDOM from 'react-dom'
import Gomo from './page/gomo'

import './index.css'

import './polyfills'

require('dayjs/locale/th')

export const init = ({ width, height, name }) => {
  let config = {
    width: width || '350px',
    height: height || '500px',
    name,
  }

  const div = document.getElementById(`${config.name}`)
  div.style.display = 'flex';

  return ReactDOM.render(
    <Gomo width={config.width} height={config.height} fixed />,
    document.querySelector(`#${config.name}`),
  )
}