export const INTERNETWITHIM = [
  { value: 'อินเทอร์เน็ตใช้งานไม่ได้', text: 'อินเทอร์เน็ตใช้งานไม่ได้' },
  { value: 'อินเทอร์เน็ต ค้าง/ช้า', text: 'อินเทอร์เน็ต ค้าง/ช้า' },
  { value: 'อินเทอร์เน็ตใช้งานไม่ได้ 1 App/Web', text: 'อินเทอร์เน็ตใช้งานไม่ได้ 1 App/Web' },
]

export const VOICEWITHIM = [
  { value: 'โทรออก/รับสายไม่ได้ (ในประเทศ)', text: 'โทรออก/รับสายไม่ได้ (ในประเทศ)' },
  { value: 'โทรออก/รับสายได้ แต่ไม่ได้ยินเสียงคู่สนทนา', text: 'โทรออก/รับสายได้ แต่ไม่ได้ยินเสียงคู่สนทนา' },
  { value: 'ปัญหาโทรออกไม่ได้(ในประเทศ)', text: 'ปัญหาโทรออกไม่ได้(ในประเทศ)' },
  { value: 'สัญญาณอ่อน/ไม่มีสัญญาณ/โทรออก-รับสายยาก', text: 'สัญญาณอ่อน/ไม่มีสัญญาณ/โทรออก-รับสายยาก' },
]

export const KYC = [
  {
    value: 'ปัญหาการแสดงตน KYC',
    text: 'ปัญหาการแสดงตน KYC',
    item: [
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดรูปซิมการ์ด',
        text: 'รูปหลังซิมการ์ด',
        discription: 'ถ่ายรูปให้เห็นเลขที่ซิมการ์ดชัดเจน',
        id: 'simcard',
        type: 'upload',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดสำเนาบัตรประชาชน',
        text: 'สำเนาบัตรประชาชน',
        discription:
          'แนบเอกสารสำเนาบัตรประชาชน ทั้งด้านหน้าและด้านหลัง ระบุข้อความ "ขอลงทะเบียนแสดงตน เบอร์ 0xx-xxx-xxxx" พร้อมเซ็นรับรองสำเนาถูกต้อง',
        id: 'idCardKyc',
        type: 'upload',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดรูปเซลฟี่หน้าตรง',
        text: 'รูปเซลฟี่หน้าตรง',
        discription: 'ถ่ายรูปเซลฟี่หน้าตรง พร้อมถือเอกสารสำเนาบัตรประชาชนตามข้อ 2',
        id: 'selfie',
        type: 'upload',
      },
    ],
  },
  {
    value: 'ขอซิมใหม่เบอร์เดิม',
    text: 'ขอซิมใหม่เบอร์เดิม',
    item: [
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดสำเนาบัตรประชาชน',
        text: 'สำเนาบัตรประชาชน',
        discription:
          'แนบเอกสารสำเนาบัตรประชาชน ทั้งด้านหน้าและด้านหลัง ระบุข้อความ "ขอให้ส่งซิมการ์ดใหม่ของ เบอร์ 0xx-xxx-xxxx เนื่องจากซิมหาย/ซิมเสีย/เปลี่ยนขนาดซิม" (ระบุสาเหตุอย่างใดอย่างหนึ่ง) พร้อมเซ็นรับรองสำเนาถูกต้อง',
        id: 'idCardNewsim',
        type: 'upload',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดรูปเซลฟี่หน้าตรง',
        text: 'รูปเซลฟี่หน้าตรง',
        discription: 'ถ่ายรูปเซลฟี่หน้าตรง พร้อมถือเอกสารสำเนาบัตรประชาชนตามข้อ 1',
        id: 'selfie',
        type: 'upload',
      },
    ],
  },
  {
    value: 'ขอเปลี่ยนซิมใหม่',
    text: 'ขอเปลี่ยนซิมใหม่',
    item: [
      {
        min: 13,
        required: true,
        requireMessage: 'กรุณากรอกเลขที่ซิม',
        requireMessageMin: 'กรุณากรอกเลขที่ซิมใหม่ให้ถูกต้อง',
        text: 'เลขที่ซิม',
        discription: 'กรอกเลขที่ซิมใหม่ 13 หลัก',
        type: 'input',
        id: 'simNo',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดรูปซิมการ์ด',
        text: 'รูปหลังซิมการ์ด',
        discription: 'ถ่ายรูปให้เห็นเลขที่ซิมการ์ดชัดเจน',
        type: 'upload',
        id: 'newSim',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดสำเนาบัตรประชาชน',
        text: 'สำเนาบัตรประชาชน',
        discription:
          'แนบเอกสารสำเนาบัตรประชาชน ทั้งด้านหน้าและด้านหลัง ระบุข้อความ "ขอให้ส่งซิมการ์ดใหม่ของ เบอร์ 0xx-xxx-xxxx เนื่องจากซิมหาย/ซิมเสีย/เปลี่ยนขนาดซิม" (ระบุสาเหตุอย่างใดอย่างหนึ่ง) พร้อมเซ็นรับรองสำเนาถูกต้อง',
        id: 'idCardNewsim',
        type: 'upload',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดรูปเซลฟี่หน้าตรง',
        text: 'รูปเซลฟี่หน้าตรง',
        discription: 'ถ่ายรูปเซลฟี่หน้าตรง พร้อมถือเอกสารสำเนาบัตรประชาชนตามข้อ 1',
        id: 'selfie',
        type: 'upload',
      },
    ],
  },
]

export const PLATFORM = [
  { name: 'เฟซบุ๊ก / Facebook', value: 'เฟซบุ๊ก / Facebook' },
  { name: 'เฟซบุ๊ก เมสเซนเจอร์ / Facebook Messenger', value: 'เฟซบุ๊ก เมสเซนเจอร์ / Facebook Messenger' },
  { name: 'ไลน์ / Line', value: 'ไลน์ / Line' },
  { name: 'ยูทูป / Youtube', value: 'ยูทูป / Youtube' },
  { name: 'อินสตาแกรม / Instagram', value: 'อินสตาแกรม / Instagram' },
  { name: 'ติ๊กต๊อก / Tik Tok', value: 'ติ๊กต๊อก / Tik Tok' },
  { name: 'ทวิตเตอร์ / Twitter', value: 'ทวิตเตอร์ / Twitter' },
  { name: 'อื่นๆ / Other', value: 'อื่นๆ / Other' },
]

export const GOMOBCWC = [
  {
    value: 'ปัญหาการซื้อแพ็กเกจในประเทศ',
    text: 'ปัญหาการซื้อแพ็กเกจในประเทศ',
    isSupTopic: true,
    isHideExample: true,
    supTopic: [
      {
        value: 'ตัดค่าแพ็กเกจสำเร็จแต่ยังใช้งานไม่ได้',
        text: 'ตัดค่าแพ็กเกจสำเร็จแต่ยังใช้งานไม่ได้',
        required: true,
      },
      {
        value: 'สมัครแพ็กเกจซ้ำ/ผิด',
        text: 'สมัครแพ็กเกจซ้ำ/ผิด',
        required: true,
      },
      {
        value: 'ตัดค่าแพ็กเกจสำเร็จแต่ระบบไม่ต่อแพ็กเกจ',
        text: 'ตัดค่าแพ็กเกจสำเร็จแต่ระบบไม่ต่อแพ็กเกจ',
        required: true,
      },
    ],
    item: [
      {
        required: true,
        requireMessage: 'กรุณาเลือกวันเวลาที่สมัคร',
        text: 'วันเวลาที่สมัคร',
        discription: 'วันเวลาที่สมัคร',
        type: 'date',
        id: 'dateIn',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกช่องทางชำระค่าแพ็กเกจ',
        text: 'ช่องทางชำระค่าแพ็กเกจ',
        discription: 'ระบุช่องทางที่ชำระอย่างชัดเจน เช่น แอปธนาคาร , บัตรเครดิต , Coin',
        type: 'input',
        id: 'paymentWay',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกชื่อแพ็กเกจ/ราคาแพ็กเกจซื้อ',
        text: 'ชื่อแพ็กเกจ/ราคาแพ็กเกจซื้อ',
        discription: 'ระบุรายละเอียดแพ็กเกจ เช่น แพ็กเกจรายเดือน 299 บาท เน็ต 30 gb',
        type: 'input',
        id: 'package',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดหลักฐานการชำระค่าบริการ',
        text: 'หลักฐานการชำระค่าบริการ',
        discription: 'แนบหน้าจอการชำระค่าบริการสำเร็จ',
        id: 'payment',
        type: 'upload',
      },
    ],
  },
  {
    value: 'ปัญหาการซื้อแพ็กเกจต่างประเทศ',
    text: 'ปัญหาการซื้อแพ็กเกจต่างประเทศ',
    isSupTopic: true,
    isHideExample: true,
    supTopic: [
      {
        value: 'ตัดค่าแพ็กเกจสำเร็จแต่ยังใช้งานไม่ได้',
        text: 'ตัดค่าแพ็กเกจสำเร็จแต่ยังใช้งานไม่ได้',
        required: true,
      },
      {
        value: 'สมัครแพ็กเกจซ้ำ/ผิด',
        text: 'สมัครแพ็กเกจซ้ำ/ผิด',
        required: true,
      },
      {
        value: 'ตัดค่าแพ็กเกจสำเร็จแต่ระบบไม่ต่อแพ็กเกจ',
        text: 'ตัดค่าแพ็กเกจสำเร็จแต่ระบบไม่ต่อแพ็กเกจ',
        required: true,
      },
    ],
    item: [
      {
        required: true,
        requireMessage: 'กรุณาเลือกวันเวลาที่สมัคร',
        text: 'วันเวลาที่สมัคร',
        discription: 'วันเวลาที่สมัคร',
        type: 'date',
        id: 'dateIn',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกช่องทางชำระค่าแพ็กเกจ',
        text: 'ช่องทางชำระค่าแพ็กเกจ',
        discription: 'ระบุช่องทางที่ชำระอย่างชัดเจน เช่น แอปธนาคาร , บัตรเครดิต , Coin',
        type: 'input',
        id: 'paymentWay',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกชื่อแพ็กเกจ/ราคาแพ็กเกจซื้อ',
        text: 'ชื่อแพ็กเกจ/ราคาแพ็กเกจซื้อ',
        discription: 'ระบุรายละเอียดแพ็กเกจ เช่น แพ็กเกจรายเดือน 299 บาท เน็ต 30 gb',
        type: 'input',
        id: 'package',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดหลักฐานการชำระค่าบริการ',
        text: 'หลักฐานการชำระค่าบริการ',
        discription: 'แนบหน้าจอการชำระค่าบริการสำเร็จ',
        id: 'payment',
        type: 'upload',
      },
    ],
  },
  {
    value: 'ปัญหาการจัดส่งSIM',
    text: 'ปัญหาการจัดส่งSIM',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณากรอกปัญหาที่พบ',
        text: 'ปัญหาที่พบ',
        discription: 'เช่น ขนส่งทำซิมหาย',
        type: 'input',
        id: 'problemNo',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกที่อยู่ในการจัดส่ง',
        text: 'ที่อยู่ในการจัดส่ง',
        discription: 'ที่อยู่',
        type: 'multipleAddress',
        id: 'addressNo',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกชื่อ-นามสกุล',
        text: 'ชื่อ-นามสกุล',
        discription: 'ชื่อ-นามสกุล',
        type: 'input',
        id: 'fullnameNo',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกเลขที่พัสดุ Tracking SIM ',
        text: 'เลขที่พัสดุ Tracking SIM ',
        discription: 'เลขที่พัสดุ Tracking SIM ',
        type: 'input',
        id: 'trackNo',
      },
    ],
  },
  {
    value: 'ยกเลิก SMS มิจฉาชีพ',
    text: 'ยกเลิก SMS มิจฉาชีพ',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณาเลือกวัน - เวลาที่พบปัญหา',
        text: 'วัน - เวลาที่พบปัญหา',
        discription: 'วัน - เวลาที่พบปัญหา',
        type: 'date',
        id: 'dateProblem',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอก Sender Name ที่พบปัญหา',
        text: 'Sender Name ที่พบปัญหา',
        discription: 'Sender Name ที่พบปัญหา',
        type: 'input',
        id: 'senderName',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกเนื้อหาข้อความ',
        text: 'เนื้อหาข้อความ',
        discription: 'เนื้อหาข้อความ',
        type: 'textArea',
        id: 'content',
        pattern: new RegExp('^[ก-๙A-Za-z0-9 ]+$'),
        isTextArea: true,
      },
    ],
  },
  {
    value: 'แก้ไข ชื่อผู้จดทะเบียน',
    text: 'แก้ไข ชื่อผู้จดทะเบียน',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณาเลือกสิ่งที่ต้องการแก้ไข',
        text: 'สิ่งที่ต้องการแก้ไข',
        discription: 'เช่น แก้ไขชื่อ หรือ นามสกุล',
        type: 'select',
        isTypeSelect: true,
        selectValue: [
          {
            value: 'ชื่อ-นามสกุลในระบบสะกดผิด',
            text: 'ชื่อ-นามสกุลในระบบสะกดผิด',
            required: true,
          },
          {
            value: 'เนื่องจากเปลี่ยนชื่อ-นามสกุลใหม่',
            text: 'เนื่องจากเปลี่ยนชื่อ-นามสกุลใหม่',
            required: true,
          },
        ],
        id: 'needToFix',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกชื่อ หรือ นามสกุล (เดิม)',
        text: 'ชื่อ หรือ นามสกุล (เดิม)',
        discription: 'ชื่อ หรือ นามสกุล (เดิม)',
        type: 'input',
        id: 'oldName',
        pattern: new RegExp('^[ก-๙A-Za-z0-9 ]+$'),
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกชื่อ หรือ นามสกุล (ใหม่)',
        text: 'ชื่อ หรือ นามสกุล (ใหม่)',
        discription: 'ชื่อ หรือ นามสกุล (ใหม่)',
        type: 'input',
        id: 'newName',
        pattern: new RegExp('^[ก-๙A-Za-z0-9 ]+$'),
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดรูปเซลฟี่หน้าตรง',
        text: 'ถ่ายรูปเซลฟี่คู่บัตรประชาชน',
        discription: 'ถ่ายรูปเซลฟี่หน้าตรง พร้อมถือเอกสารสำเนาบัตรประชาชนตามข้อ 5',
        id: 'selfie',
        type: 'upload',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดสำเนาบัตรประชาชน',
        text: 'สำเนาบัตรประชาชน',
        discription:
          'แนบเอกสารสำเนาบัตรประชาชน ทั้งด้านหน้าและด้านหลัง ระบุข้อความ "ขอลงทะเบียนแสดงตน เบอร์ 0xx-xxx-xxxx" พร้อมเซ็นรับรองสำเนาถูกต้อง',
        id: 'idCardKyc',
        type: 'upload',
      },
    ],
  },
  {
    value: 'ปัญหาการใช้งานโรมมิ่ง',
    text: 'ปัญหาการใช้งานโรมมิ่ง',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณากรอกอาการที่พบ',
        text: 'อาการที่พบ',
        discription: 'อาการที่พบ',
        type: 'input',
        id: 'problemRoaming',
        pattern: new RegExp('^[ก-๙A-Za-z0-9 ]+$'),
      },
      {
        required: true,
        requireMessage: 'กรุณาเลือกวัน - เวลาที่พบปัญหา',
        text: 'วัน - เวลาที่พบปัญหา',
        discription: 'วัน - เวลาที่พบปัญหา',
        type: 'date',
        id: 'dateRoaming',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกประเทศ/เมืองที่ใช้งาน(ภาษาอังกฤษ)',
        text: 'ประเทศ/เมืองที่ใช้งาน(ภาษาอังกฤษ)',
        discription: 'ประเทศ/เมืองที่ใช้งาน(ภาษาอังกฤษ)',
        type: 'input',
        id: 'countryEn',
        pattern: new RegExp('^[A-Za-z0-9/ ]+$'),
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกบริเวณใช้งาน(ถนน/สถานที่สำคัญ(ภาษาอังกฤษ)',
        text: 'บริเวณใช้งาน(ถนน/สถานที่สำคัญ(ภาษาอังกฤษ)',
        discription: 'บริเวณใช้งาน(ถนน/สถานที่สำคัญ(ภาษาอังกฤษ)',
        type: 'input',
        id: 'placeEn',
        pattern: new RegExp('^[A-Za-z0-9/ ]+$'),
      },
      {
        required: true,
        requireMessage: 'กรุณากรอก Operator ที่ต้องการใช้งาน',
        text: 'Operator ที่ต้องการใช้งาน',
        discription: 'Operator ที่ต้องการใช้งาน',
        type: 'input',
        id: 'operator',
        pattern: new RegExp('^[ก-๙A-Za-z0-9 ]+$'),
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกยี่ห้อ/รุ่น  มือถือที่ใช้งาน',
        text: 'ยี่ห้อ/รุ่น  มือถือที่ใช้งาน',
        discription: 'ยี่ห้อ/รุ่น  มือถือที่ใช้งาน',
        type: 'input',
        id: 'mobileBrand',
        pattern: new RegExp('^[ก-๙A-Za-z0-9 ]+$'),
      },
    ],
  },
  {
    value: 'ขอใบเสร็จรับเงิน/ใบกำกับภาษี',
    text: 'ขอใบเสร็จรับเงิน/ใบกำกับภาษี',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณาเลือกช่องทางการจัดส่ง',
        text: 'ช่องทางการจัดส่ง',
        type: 'radioInvoice',
        id: 'receiverType',
        renderFormsEmail: [
          {
            maxLength: 13,
            minLength: 13,
            required: true,
            requireMessage: 'กรุณากรอกเลขบัตรประจำตัวประชาชน 13 หลัก',
            text: 'เลขบัตรประจำตัวประชาชน 13 หลัก',
            discription: 'เลขบัตรประจำตัวประชาชน 13 หลัก',
            type: 'input',
            id: 'idCard',
            pattern: new RegExp('^[0-9]*$'),
            isNumberOnly: true,
            step: 2,
          },
          {
            required: true,
            requireMessage: 'กรุณากรอกที่อยู่',
            text: 'ที่อยู่สำหรับออกใบเสร็จ / ใบกำกับภาษี',
            discription: 'ที่อยู่',
            type: 'multipleAddress',
            idAddress: 'invoiceAddress',
            idProvince: 'invoiceProvince',
            idDistrict: 'invoiceDistrict',
            idSubDistrict: 'invoiceSubDistrict',
            idZipcode: 'invoiceZipcode',
            step: 3,
          },
          {
            required: true,
            requireMessage: 'กรุณากรอก Email จัดส่งเอกสาร',
            text: 'Email จัดส่งเอกสาร',
            discription: 'Email จัดส่งเอกสาร',
            type: 'input',
            id: 'receiverEmail',
            pattern: new RegExp('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[a-zA-Z0-9]{2,128}$'),
            step: 4,
          },
          {
            required: true,
            requireMessage: 'กรุณาเลือกวันที่ซื้อแพ็กเกจ',
            text: 'วันที่ซื้อแพ็กเกจ',
            discription: 'วันที่ซื้อแพ็กเกจ',
            type: 'date',
            id: 'dateInvoice',
            step: 5,
          },
          {
            required: true,
            requireMessage: 'กรุณาเลือกเดือน',
            text: 'เดือนที่ต้องการขอรับใบเสร็จรับเงิน / กำกับภาษี',
            type: 'monthsInvoice',
            id: 'monthsInvoice',
            idPackage: 'idPackage',
            step: 6,
          },
        ],
        renderFormsPostOffice: [
          {
            maxLength: 10,
            required: true,
            requireMessage: 'กรุณากรอกเบอร์ GOMO',
            text: 'เบอร์ GOMO',
            discription: 'เบอร์ GOMO',
            isNumberOnly: true,
            type: 'input',
            id: 'gomoMobileNo',
            pattern: new RegExp('^[0-9]*$'),
            step: 2,
          },
          {
            required: true,
            requireMessage: 'กรุณากรอกชื่อ - สกุล',
            text: 'ชื่อ - สกุล',
            discription: 'ชื่อ - สกุล',
            type: 'input',
            id: 'receiverName',
            pattern: new RegExp('^[ก-๙A-Za-z0-9 ]+$'),
            step: 3,
          },
          {
            maxLength: 13,
            minLength: 13,
            required: true,
            requireMessage: 'กรุณากรอกเลขบัตรประจำตัวประชาชน 13 หลัก',
            text: 'เลขบัตรประจำตัวประชาชน 13 หลัก',
            discription: 'เลขบัตรประจำตัวประชาชน 13 หลัก',
            isNumberOnly: true,
            type: 'input',
            id: 'idCard',
            pattern: new RegExp('^[0-9]*$'),
            step: 4,
          },
          {
            id: 'idInvoice',
            required: true,
            requireMessage: 'กรุณากรอกที่อยู่',
            text: 'ที่อยู่สำหรับออกใบเสร็จ / ใบกำกับภาษี',
            discription: 'ที่อยู่',
            type: 'multipleAddress',
            idAddress: 'invoiceAddress',
            idProvince: 'invoiceProvince',
            idDistrict: 'invoiceDistrict',
            idSubDistrict: 'invoiceSubDistrict',
            idZipcode: 'invoiceZipcode',
            step: 5,
          },
          {
            id: 'idReceiver',
            isShowRadio: true,
            required: true,
            requireMessage: 'กรุณากรอกที่อยู่',
            text: 'ที่อยู่ในการจัดส่ง',
            discription: 'ที่อยู่',
            type: 'multipleAddress',
            idAddress: 'receiverAddress',
            idProvince: 'receiverProvince',
            idDistrict: 'receiverDistrict',
            idSubDistrict: 'receiverSubDistrict',
            idZipcode: 'receiverZipcode',
            step: 6,
          },
          {
            required: true,
            requireMessage: 'กรุณาเลือกวันที่ซื้อแพ็กเกจ',
            text: 'วันที่ซื้อแพ็กเกจ',
            discription: 'วันที่ซื้อแพ็กเกจ',
            type: 'date',
            id: 'dateInvoice',
            step: 7,
          },
          {
            required: true,
            requireMessage: 'กรุณาเลือกเดือน',
            text: 'เดือนที่ต้องการขอรับใบเสร็จรับเงิน / กำกับภาษี',
            type: 'monthsInvoice',
            id: 'monthsInvoice',
            idPackage: 'idPackage',
            step: 8,
          },
        ],
      },
    ],
  },
  {
    value: 'ปัญหาการใช้งานเน็ตมือถือ',
    text: 'ปัญหาการใช้งานเน็ตมือถือ',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณากรอกอาการที่พบ',
        text: 'อาการที่พบ',
        discription: 'ตัวอย่าง : เน็ตหมุน / ช้า / ค้างหน้าขาว',
        type: 'input',
        id: 'subjectProblem',
      },
      {
        required: true,
        requireMessage: 'กรุณาเลือกวัน - เวลาที่พบปัญหา',
        text: 'วัน - เวลาที่พบปัญหา',
        discription: 'วัน - เวลาที่พบปัญหา',
        type: 'date',
        id: 'problemDate',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกที่อยู่ ที่พบปัญหา',
        text: 'ที่อยู่ ที่พบปัญหา',
        discription: 'ที่อยู่',
        type: 'multipleAddress',
        id: 'problemAddress',
      },

      {
        required: true,
        requireMessage: 'กรุณากรอกจุดสังเกต 500 เมตร',
        text: 'จุดสังเกต 500 เมตร',
        discription: 'สถานที่สำคัญต่างๆ เช่น วัด / โรงพยาบาล / โรงเรียน  / สถานที่ราชการ',
        type: 'input',
        id: 'nearbyLandmark',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกแอป หรือเว็ปไซต์ที่พบปัญหา',
        text: 'แอป หรือเว็ปไซต์ที่พบปัญหา',
        discription: 'ตัวอย่าง : Facebook / TikTok / Youtube / Instagram',
        type: 'input',
        id: 'platformProblem',
      },
    ],
  },
  {
    value: 'ปัญหาการโทรเข้า-โทรออก',
    text: 'ปัญหาการโทรเข้า-โทรออก',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณากรอกอาการที่พบ',
        text: 'อาการที่พบ',
        discription: 'ตัวอย่าง : สัญญาณอ่อน / เสียงขาดๆหายๆ / โทรเข้า – ออก ได้ยาก',
        type: 'input',
        id: 'subjectProblem',
      },
      {
        required: true,
        requireMessage: 'กรุณาเลือกวัน - เวลาที่พบปัญหา',
        text: 'วัน - เวลาที่พบปัญหา',
        discription: 'วัน - เวลาที่พบปัญหา',
        type: 'date',
        id: 'problemDate',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกที่อยู่ ที่พบปัญหา',
        text: 'ที่อยู่ ที่พบปัญหา',
        discription: 'ที่อยู่',
        type: 'multipleAddress',
        id: 'problemAddress',
      },

      {
        required: true,
        requireMessage: 'กรุณากรอกจุดสังเกต 500 เมตร',
        text: 'จุดสังเกต 500 เมตร',
        discription: 'สถานที่สำคัญต่างๆ เช่น วัด / โรงพยาบาล / โรงเรียน  / สถานที่ราชการ',
        type: 'input',
        id: 'nearbyLandmark',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกเบอร์ปลายทางที่พบปัญหา',
        text: 'เบอร์ปลายทางที่พบปัญหา',
        discription: 'ตัวอย่าง : กรุณาระบุเบอร์ปลายทางที่พบปัญหา (อย่างน้อย 1 เบอร์)',
        type: 'input',
        id: 'destinationMobileNo',
      },
    ],
  },
  {
    value: 'ปัญหาการย้ายค่ายออกจาก GOMO',
    text: 'ปัญหาการย้ายค่ายออกจาก GOMO',
    isSupTopic: false,
    item: [
      {
        maxLength: 10,
        isNumberOnly: true,
        pattern: new RegExp('^0[0-9]{9}$'),
        required: true,
        requireMessage: 'กรุณากรอกเบอร์ที่พบปัญหา',
        text: 'เบอร์ที่พบปัญหา',
        discription: 'เบอร์ที่พบปัญหา',
        type: 'input',
        id: 'mobileNoProblem',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกปัญหาที่พบ',
        text: 'ปัญหาที่พบ',
        discription: 'ปัญหาที่พบ',
        type: 'input',
        id: 'subjectProblem',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกเครือข่ายที่ใช้งาน',
        text: 'เครือข่ายที่ใช้งาน',
        discription: 'เครือข่ายที่ใช้งาน',
        type: 'input',
        id: 'networkUsage',
      },
    ],
  },
  {
    value: 'สอบถามรหัส PUK',
    text: 'สอบถามรหัส PUK',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดรูปเซลฟี่หน้าตรง',
        text: 'ถ่ายรูปเซลฟี่คู่บัตรประชาชน',
        discription: 'ถ่ายรูปเซลฟี่หน้าตรง พร้อมถือเอกสารสำเนาบัตรประชาชน',
        id: 'selfie',
        type: 'upload',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดสำเนาบัตรประชาชน',
        text: 'สำเนาบัตรประชาชน',
        discription:
          'แนบเอกสารสำเนาบัตรประชาชน ทั้งด้านหน้าและด้านหลัง ระบุข้อความ "ขอลงทะเบียนแสดงตน เบอร์ 0xx-xxx-xxxx" พร้อมเซ็นรับรองสำเนาถูกต้อง',
        id: 'idCardKyc',
        type: 'upload',
      },
    ],
  },
  {
    value: 'เปิดบริการ HD Voice (VoLTE)',
    text: 'เปิดบริการ HD Voice (VoLTE)',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณากรอกเครื่องที่ใช้งาน ยี่ห้อ/รุ่น',
        text: 'เครื่องที่ใช้งาน ยี่ห้อ/รุ่น',
        discription: 'เครื่องที่ใช้งาน ยี่ห้อ/รุ่น',
        type: 'input',
        id: 'brand',
      },
    ],
  },
  {
    value: 'เปิดบริการ WiFi Calling (VoWiFi)',
    text: 'เปิดบริการ WiFi Calling (VoWiFi)',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณากรอกเครื่องที่ใช้งาน ยี่ห้อ/รุ่น',
        text: 'เครื่องที่ใช้งาน ยี่ห้อ/รุ่น',
        discription: 'เครื่องที่ใช้งาน ยี่ห้อ/รุ่น',
        type: 'input',
        id: 'brand',
      },
    ],
  },
  {
    value: 'เรื่องอื่นๆ',
    text: 'เรื่องอื่นๆ',
    isSupTopic: false,
    isHideExample: true,
    item: [
      {
        required: true,
        requireMessage: 'กรุณากรอกปัญหาที่พบ',
        text: 'ปัญหาที่พบ',
        discription: 'ปัญหาที่พบ',
        type: 'input',
        id: 'subjectProblem',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดหน้าจอที่พบปัญหา',
        text: 'หน้าจอที่พบปัญหา',
        discription: 'แนบหน้าจอที่พบปัญหา',
        id: 'idCardKyc',
        type: 'upload',
      },
    ],
  },
  {
    value: 'ปัญหา eSIM',
    text: 'ปัญหา eSIM',
    isSupTopic: false,
    isHideExample: true,
    item: [
      {
        required: true,
        requireMessage: 'กรุณากรอกปัญหาที่พบ อย่างน้อย 5 ตัวอักษร',
        text: 'ระบุปัญหาที่พบ',
        discription: 'ระบุปัญหาที่พบ',
        type: 'input',
        id: 'subjectProblem',
        minLength: 5,
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกเครื่องที่ใช้งาน ยี่ห้อ/รุ่น',
        text: 'เครื่องที่ใช้งาน ยี่ห้อ/รุ่น',
        discription: 'เครื่องที่ใช้งาน ยี่ห้อ/รุ่น',
        id: 'brand',
        type: 'input',
      },
      {
        required: true,
        requireMessage: 'กรุณาเลือกสัญญาณที่ใช้งาน',
        text: 'สัญญาณที่ใช้งาน',
        discription: 'สัญญาณที่ใช้งาน',
        id: 'signalUsage',
        type: 'checkbox',
      },
    ],
  },
  {
    value: 'ยกเลิก eSIM',
    text: 'ยกเลิก eSIM',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณากรอกสาเหตุที่ยกเลิก',
        text: 'สาเหตุที่ยกเลิก',
        discription: 'สาเหตุที่ยกเลิก',
        type: 'input',
        id: 'cancelReason',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกที่อยู่ในการจัดส่ง',
        text: 'ที่อยู่จัดส่ง SIM ใหม่',
        discription: 'ที่อยู่',
        type: 'multipleAddress',
        id: 'newDeliverySimAddress',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดรูปเซลฟี่หน้าตรง',
        text: 'ถ่ายรูปเซลฟี่คู่บัตรประชาชน',
        discription: 'ถ่ายรูปเซลฟี่หน้าตรง พร้อมถือเอกสารสำเนาบัตรประชาชน',
        id: 'selfie',
        type: 'upload',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดสำเนาบัตรประชาชน',
        text: 'สำเนาบัตรประชาชน',
        discription:
          'แนบเอกสารสำเนาบัตรประชาชน ทั้งด้านหน้าและด้านหลัง ระบุข้อความ "ขอลงทะเบียนแสดงตน เบอร์ 0xx-xxx-xxxx" พร้อมเซ็นรับรองสำเนาถูกต้อง',
        id: 'idCardKyc',
        type: 'upload',
      },
    ],
  },
  {
    value: 'Block call/SMS มิจฉาชีพ',
    text: 'Block call/SMS มิจฉาชีพ',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณากรอก ปัญหา / Error หน้าจอที่พบ',
        text: 'ปัญหา / Error หน้าจอที่พบ',
        discription: 'ปัญหา / Error หน้าจอที่พบ',
        type: 'input',
        id: 'screenErrorProblem',
      },
      {
        required: true,
        requireMessage: 'กรุณาเลือกวัน - เวลาที่พบปัญหา',
        text: 'วัน - เวลาที่พบปัญหา',
        discription: 'วัน - เวลาที่พบปัญหา',
        type: 'date',
        id: 'problemDate',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอก Sender Name / เบอร์โทร ที่พบปัญหา',
        text: 'Sender Name / เบอร์โทร ที่พบปัญหา',
        discription: 'Sender Name / เบอร์โทร ที่พบปัญหา',
        type: 'input',
        id: 'senderProblem',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกเนื้อหาข้อความ',
        text: 'เนื้อหาข้อความ',
        discription: 'เนื้อหาข้อความ',
        type: 'textArea',
        id: 'messageContent',
      },
    ],
  },
  {
    value: 'ปัญหา AIS Point',
    text: 'ปัญหา AIS Point',
    isSupTopic: false,
    isHideExample: true,
    item: [
      {
        required: true,
        requireMessage: 'กรุณาเลือกวัน - เวลาที่ทำรายการ',
        text: 'วัน - เวลาที่ทำรายการ',
        discription: 'วัน - เวลาที่ทำรายการ',
        type: 'date',
        id: 'transactionDate',
      },
      {
        minLength: 5,
        required: true,
        requireMessage: 'กรุณากรอกรายละเอียดปัญหา  อย่างน้อย 5 ตัวอักษร',
        text: 'รายละเอียดปัญหา ',
        discription: 'รายละเอียดปัญหา',
        type: 'input',
        id: 'problemDetail',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกร้านที่แลก',
        text: 'ร้านที่แลก ',
        discription: 'ร้านที่แลก',
        type: 'input',
        id: 'shopExchange',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกจำนวนพ้อยท์แลก',
        text: 'จำนวนพ้อยท์แลก ',
        discription: 'จำนวนพ้อยท์แลก',
        type: 'input',
        id: 'pointNumber',
      },
      {
        required: true,
        requireMessage: 'กรุณาอัปโหลดหลักฐานการชำระ',
        text: 'หลักฐานการชำระ',
        discription: 'แนบหลักฐานการชำระ',
        id: 'idCardKyc',
        type: 'upload',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกมือถือยี่ห้อ/รุ่น',
        text: 'มือถือยี่ห้อ/รุ่น ',
        discription: 'มือถือยี่ห้อ/รุ่น',
        type: 'input',
        id: 'brand',
      },
    ],
  },
  {
    value: 'ปัญหา Application GOMO',
    text: 'ปัญหา Application GOMO',
    isSupTopic: false,
    isHideExample: true,
    item: [
      {
        required: true,
        requireMessage: 'กรุณาเลือกวัน - เวลาที่ทำรายการ',
        text: 'วัน - เวลาที่ทำรายการ',
        discription: 'วัน - เวลาที่ทำรายการ',
        type: 'date',
        id: 'transactionDate',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกปัญหาที่พบ',
        text: 'ปัญหาที่พบ ',
        discription: 'ปัญหาที่พบ',
        type: 'input',
        id: 'subjectProblem',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกเมนูที่พบปัญหา',
        text: 'เมนูที่พบปัญหา ',
        discription: 'เมนูที่พบปัญหา',
        type: 'input',
        id: 'menuProblem',
      },
      {
        required: true,
        requireMessage: 'กรุณาแนบภาพ Error ที่พบบนหน้าจอ',
        text: 'รูป Error ที่พบบนหน้าจอ ',
        discription: 'แนบภาพ Error ที่พบบนหน้าจอ',
        type: 'upload',
        id: 'selfie',
      },
      {
        required: false,
        requireMessage: 'กรุณาอัปโหลดหลักฐานการชำระ',
        text: 'รูป Capture หน้าจอ (ถ้ามี)',
        discription: 'แนบรูป Capture หน้าจอ (ถ้ามี)',
        type: 'upload',
        id: 'idCardKyc',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกเครื่องที่ใช้งาน (iOS/Android และ Version ของ OS)',
        text: 'เครื่องที่ใช้งาน (iOS/Android และ Version ของ OS) ',
        discription: 'เครื่องที่ใช้งาน (iOS/Android และ Version ของ OS)',
        type: 'input',
        id: 'deviceUsage',
      },
      {
        required: true,
        requireMessage: 'กรุณาเลือกสัญญาณที่ใช้งาน',
        text: 'สัญญาณที่ใช้งาน',
        discription: 'สัญญาณที่ใช้งาน',
        id: 'signalUsage',
        type: 'checkbox',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกวิธี Login เข้าใช้งาน',
        text: 'วิธี Login เข้าใช้งาน ',
        discription: 'เช่น: OTP, PIN Code, แสกนนิ้วมือ, การแสกนหน้า',
        type: 'input',
        id: 'howToLogin',
      },
    ],
  },
  {
    value: 'ติดตามการย้ายค่าย',
    text: 'ติดตามการย้ายค่าย',
    isSupTopic: true,
    isHideParentComponent: true,
    supTopic: [
      {
        value: 'ย้ายมาเป็น GOMO',
        text: 'ย้ายมาเป็น GOMO',
        required: true,
      },
      {
        value: 'ย้ายไปเป็นเครือข่ายอื่น',
        text: 'ย้ายไปเป็นเครือข่ายอื่น',
        required: true,
      },
    ],
    item: [
      {
        valueSup: 'ย้ายมาเป็น GOMO',
        sub_component: [
          {
            required: true,
            requireMessage: 'กรุณากรอกเบอร์ที่ย้ายมาใช้งาน GOMO',
            text: 'เบอร์ที่ย้ายมาใช้งาน GOMO',
            discription: 'เบอร์ที่ย้ายมาใช้งาน GOMO',
            type: 'input',
            id: 'mobileNoMoving',
            isNumberOnly: true,
            maxLength: 10,
            pattern: new RegExp('^0[0-9]{9}$'),
          },
          {
            required: true,
            requireMessage: 'กรุณากรอกเครือข่ายเดิม',
            text: 'เครือข่ายเดิม',
            discription: 'เครือข่ายเดิม',
            type: 'input',
            id: 'oldNetwork',
          },
          {
            required: true,
            requireMessage: 'กรุณาเลือกวันที่และเวลาที่ย้ายเครือข่าย',
            text: 'วันที่และเวลาที่ย้ายเครือข่าย',
            discription: 'วันที่และเวลาที่ย้ายเครือข่าย',
            type: 'date',
            id: 'movingDate',
          },
        ],
      },
      {
        valueSup: 'ย้ายไปเป็นเครือข่ายอื่น',
        sub_component: [
          {
            required: true,
            requireMessage: 'กรุณากรอกเครือข่ายปลายทาง',
            text: 'เครือข่ายปลายทาง',
            discription: 'เครือข่ายปลายทาง',
            type: 'input',
            id: 'destinationNetwork',
          },
          {
            required: true,
            requireMessage: 'กรุณาเลือกวันที่และเวลาที่ย้ายเครือข่าย',
            text: 'วันที่และเวลาที่ย้ายเครือข่าย',
            discription: 'วันที่และเวลาที่ย้ายเครือข่าย',
            type: 'date',
            id: 'movingDate',
          },
        ],
      },
    ],
  },

  {
    value: 'Port out GOMO',
    text: 'Port out GOMO',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณากรอกต้องการย้ายไปเครือข่ายใด',
        text: 'ต้องการย้ายไปเครือข่ายใด ',
        discription: 'ต้องการย้ายไปเครือข่ายใด',
        type: 'input',
        id: 'portOutNetwork',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกสาเหตุที่ต้องการย้ายเครือข่าย',
        text: 'สาเหตุที่ต้องการย้ายเครือข่าย ',
        discription: 'สาเหตุที่ต้องการย้ายเครือข่าย',
        type: 'input',
        id: 'portOutReason',
      },
    ],
  },

  {
    value: 'ปัญหาการสั่งซื้อซิม',
    text: 'ปัญหาการสั่งซื้อซิม',
    isSupTopic: false,
    item: [
      {
        required: true,
        requireMessage: 'กรุณากรอกเบอร์ GOMO ที่ต้องการซื้อ',
        text: 'เบอร์ GOMO ที่ต้องการซื้อ ',
        discription: 'เบอร์ GOMO ที่ต้องการซื้อ',
        isNumberOnly: true,
        maxLength: 10,
        pattern: new RegExp('^0[0-9]{9}$'),
        type: 'input',
        id: 'mobileNoBuying',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกปัญหาที่พบ',
        text: 'ปัญหาที่พบ ',
        discription: 'เช่น จ่ายเงินไม่ได้',
        type: 'input',
        id: 'subjectProblem',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกซื้อผ่านช่องทางใด',
        text: 'ซื้อผ่านช่องทางใด ',
        discription: 'เช่น เว็บไซต์ GOMO / Lazada / Shopee',
        type: 'input',
        id: 'buyingMethod',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกวิธีการชำระค่าซิม GOMO',
        text: 'วิธีการชำระค่าซิม GOMO',
        discription: 'วิธีการชำระค่าซิม GOMO',
        id: 'paymentMethod',
        type: 'input',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกโปรโมชั่นที่เลือก',
        text: 'โปรโมชั่นที่เลือก',
        discription: 'โปรโมชั่นที่เลือก',
        id: 'promotion',
        type: 'input',
      },
      {
        required: true,
        requireMessage: 'กรุณากรอกที่อยู่ในการจัดส่ง',
        text: 'ที่อยู่ในการจัดส่ง',
        discription: 'ที่อยู่',
        type: 'multipleAddress',
        id: 'deliverySimAddress',
      },

      {
        required: true,
        requireMessage: 'กรุณาเลือกวันเวลาที่พบปัญหา',
        text: 'วันเวลาที่พบปัญหา',
        discription: 'วันเวลาที่พบปัญหา',
        type: 'date',
        id: 'problemDate',
      },
    ],
  },
]
