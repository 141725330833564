import React, { Component } from 'react'
import { Input, Button, Form, DatePicker, Select } from 'antd'
import {
  StyledWrapForm,
  StyledWrapContainer,
  StyledWrapFormContent,
  StyledSection,
  StyledHeader,
  StyledTextLabel,
  StyledTextHeader,
  StyledTextSubHeader,
  StyledTextTopic,
  StyledWrapButton,
} from './style'

import { PROVINCE, VOICEWITHIM } from '../../../constants'
import { getDisabledTime } from '../../../functions'
import { CaretDownOutlined } from '@ant-design/icons'

import locale from 'antd/es/date-picker/locale/th_TH'
const dayjs = require('dayjs')

export default class VoiceProblemFormWebview extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { form, provinceList } = this.props
    return (
      <StyledWrapForm className="form-internet-problem">
        <StyledHeader>
          <StyledTextHeader>แจ้งปัญหาการใช้งานโทรศัพท์</StyledTextHeader>
        </StyledHeader>
        <StyledWrapContainer>
          <StyledTextSubHeader> หมายเลขโทรศัพท์ที่พบปัญหาการใช้งานการโทร : {this.props.mobileNo}</StyledTextSubHeader>
          <StyledWrapFormContent>
            <Form
              layout="vertical"
              ref={this.props.formRef}
              onFinish={this.props.onFinish}
              autoComplete="off"
              style={{ width: '100%' }}
            >
              <StyledSection>
                <StyledTextTopic>อาการที่พบ *</StyledTextTopic>
                <Form.Item
                  name="problemMessage"
                  rules={[
                    {
                      required: true,
                      message: 'กรุณาระบุอาการที่พบ',
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    suffixIcon={<CaretDownOutlined style={{ fontSize: '16px' }} />}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    dropdownStyle={{ zIndex: 999999 }}
                    name="problemMessage"
                    placeholder="กรุณาเลือก"
                    onChange={this.props.setSelect('problemMessage')}
                  >
                    {VOICEWITHIM.map((ele, i) => {
                      return (
                        <Select.Option style={{ fontSize: 21 }} key={i} value={ele.value}>
                          {ele.text}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                <StyledTextLabel>วัน เวลา ที่พบปัญหา *</StyledTextLabel>
                <Form.Item
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: 'กรุณาระบุวันเวลาที่พบปัญหา',
                    },
                  ]}
                >
                  <DatePicker
                    inputReadOnly
                    getPopupContainer={(trigger) => trigger.parentElement}
                    format={'DD/MM/YYYY HH:mm'}
                    disabledDate={(current) => {
                      return current && current.valueOf() > Date.now()
                    }}
                    disabledTime={(current) => {
                      const now = dayjs().format('YYYY-MM-DD HH')
                      if (dayjs(current).format('YYYY-MM-DD HH') === now) {
                        return getDisabledTime(dayjs().format('HH'), dayjs().format('mm'))
                      }
                    }}
                    showTime
                    locale={locale}
                    value={form.date}
                    name="date"
                    placeholder="กรุณาเลือกวันที่-เวลา"
                  />
                </Form.Item>

                <StyledTextTopic>ระบุพื้นที่ ที่พบปัญหาการใช้งาน</StyledTextTopic>
              </StyledSection>
              <StyledSection style={{ justifyContent: 'space-between' }} width={'100%'} flexDirection={'row'}>
                <StyledSection>
                  <StyledTextLabel>เลขที่ *</StyledTextLabel>
                  <Form.Item
                    name="addressNo"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณากรอกเลขที่',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="กรุณากรอกเลขที่" autoComplete="off" name="addressNo" value={form.addressNo} />
                  </Form.Item>
                </StyledSection>
                <StyledSection>
                  <StyledTextLabel>หมู่บ้าน/คอนโด/สถานที่ *</StyledTextLabel>
                  <Form.Item
                    name="addressMooban"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณากรอกหมู่บ้าน',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="กรุณากรอกหมู่บ้าน"
                      autoComplete="off"
                      name="addressMooban"
                      value={form.addressMooban}
                    />
                  </Form.Item>
                </StyledSection>
                <StyledSection>
                  <StyledTextLabel> ตึก/อาคาร</StyledTextLabel>
                  <Form.Item name="addressBuilding">
                    <Input
                      placeholder="หากมีตำแหน่งของตึก อาคาร โปรดระบุเพิ่ม เช่น ตึก 1 ,ตึก A"
                      autoComplete="off"
                      name="addressBuilding"
                      value={form.addressBuilding}
                    />
                  </Form.Item>
                </StyledSection>
                <StyledSection>
                  <StyledTextLabel>ชั้น</StyledTextLabel>
                  <Form.Item name="addressFloor">
                    <Input autoComplete="off" name="addressFloor" value={form.addressFloor} />
                  </Form.Item>
                </StyledSection>

                <StyledSection>
                  <StyledTextLabel>หมู่</StyledTextLabel>
                  <Form.Item name="addressMoo">
                    <Input autoComplete="off" name="addressMoo" value={form.addressMoo} />
                  </Form.Item>
                </StyledSection>

                <StyledSection>
                  <StyledTextLabel>ตรอก/ซอย</StyledTextLabel>
                  <Form.Item name="addressSoi">
                    <Input autoComplete="off" name="addressSoi" value={form.addressSoi} />
                  </Form.Item>
                </StyledSection>

                <StyledSection>
                  <StyledTextLabel>ถนน</StyledTextLabel>
                  <Form.Item name="addressRoad">
                    <Input autoComplete="off" name="addressRoad" value={form.addressRoad} />
                  </Form.Item>
                </StyledSection>

                <StyledSection>
                  <StyledTextLabel>จังหวัด *</StyledTextLabel>
                  <Form.Item
                    name="addressProvince"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณาเลือกจังหวัด',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={<CaretDownOutlined style={{ fontSize: '16px' }} />}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      dropdownStyle={{ zIndex: 999999 }}
                      showSearch
                      name="addressProvince"
                      placeholder="กรุณาเลือก"
                      onChange={this.props.setSelect('addressProvince')}
                    >
                      {PROVINCE.map((ele, i) => {
                        return (
                          <Select.Option style={{ fontSize: 21 }} key={i} value={ele.value}>
                            {ele.text}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </StyledSection>

                <StyledSection>
                  <StyledTextLabel>เขต/อำเภอ *</StyledTextLabel>
                  <Form.Item
                    name="addressAmphur"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณาเลือกเขต/อำเภอ',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={<CaretDownOutlined style={{ fontSize: '16px' }} />}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      dropdownStyle={{ zIndex: 999999 }}
                      disabled={provinceList.length === 0}
                      showSearch
                      name="addressAmphur"
                      placeholder="กรุณาเลือก"
                      onChange={this.props.setSelect('addressAmphur')}
                    >
                      {this.props.renderProvinceList('province', form.addressProvince, 'district').map((ele, i) => {
                        if (ele.province === form.addressProvince) {
                          return (
                            <Select.Option style={{ fontSize: 21 }} key={i} value={ele.district}>
                              {ele.district}
                            </Select.Option>
                          )
                        } else {
                          return null
                        }
                      })}
                    </Select>
                  </Form.Item>
                </StyledSection>

                <StyledSection>
                  <StyledTextLabel>แขวง/ตำบล *</StyledTextLabel>
                  <Form.Item
                    name="addressTumbol"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณาเลือกแขวง/ตำบล',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={<CaretDownOutlined style={{ fontSize: '16px' }} />}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      dropdownStyle={{ zIndex: 999999 }}
                      disabled={!form.addressProvince || !form.addressAmphur}
                      showSearch
                      name="addressTumbol"
                      placeholder="กรุณาเลือก"
                      onChange={this.props.setSelect('addressTumbol')}
                    >
                      {this.props.renderProvinceList('district', form.addressAmphur, 'subdistrict').map((ele, i) => {
                        if (ele.district === form.addressAmphur) {
                          return (
                            <Select.Option style={{ fontSize: 21 }} key={i} value={ele.subdistrict}>
                              {ele.subdistrict}
                            </Select.Option>
                          )
                        } else {
                          return null
                        }
                      })}
                    </Select>
                  </Form.Item>
                </StyledSection>

                <StyledSection>
                  <StyledTextLabel>รหัสไปรษณีย์ *</StyledTextLabel>
                  <Form.Item
                    name="addressPostCode"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณาเลือกรหัสไปรษณีย์',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={<CaretDownOutlined style={{ fontSize: '16px' }} />}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      dropdownStyle={{ zIndex: 999999 }}
                      disabled={!form.addressProvince || !form.addressAmphur || !form.addressTumbol}
                      name="addressPostCode"
                      placeholder="กรุณาเลือก"
                      onChange={this.props.setSelect('addressPostCode')}
                    >
                      {this.props.renderProvinceList('subdistrict', form.addressTumbol, 'postalcode').map((ele, i) => {
                        if (ele.subdistrict === form.addressTumbol) {
                          return (
                            <Select.Option style={{ fontSize: 21 }} key={i} value={ele.postalcode}>
                              {ele.postalcode}
                            </Select.Option>
                          )
                        } else {
                          return null
                        }
                      })}
                    </Select>
                  </Form.Item>
                </StyledSection>

                <StyledSection>
                  <StyledTextLabel>จุดสังเกตพื้นที่ใกล้เคียงที่พบปัญหา เช่น วัด สถานที่ราชการ *</StyledTextLabel>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'กรุณาระบุจุดสังเกตพื้นที่ใกล้เคียงที่พบปัญหา',
                        whitespace: true,
                      },
                    ]}
                    name="problemLandmark"
                  >
                    <Input.TextArea
                      autoSize
                      placeholder="ระบุชื่อสถานที่ เช่น ชื่อวัด ชื่อโรงเรียน ฯลฯ ระบุจุดสังเกตระยะไม่เกิน 500 เมตร"
                      rows={2}
                      autoComplete="off"
                      name="problemLandmark"
                      value={form.problemLandmark}
                    />
                  </Form.Item>
                </StyledSection>

                {form.problemMessage !== 'สัญญาณอ่อน/ไม่มีสัญญาณ/โทรออก-รับสายยาก' && (
                  <>
                    <StyledSection>
                      <StyledTextLabel>ระบุหมายเลขปลายทางที่โทรออกแล้วพบปัญหา (อย่างน้อย 1 เบอร์) *</StyledTextLabel>
                      <Form.Item
                        normalize={(value) => value.replace(/[^\d]+/g, '')}
                        rules={[
                          {
                            required: true,
                            message: 'กรุณากรอกหมายเลข',
                            whitespace: true,
                          },
                          {
                            pattern: new RegExp('^0[0-9]{9}$'),
                            message: 'ท่านกรอกข้อมูลไม่ถูกต้อง กรุณากรอกใหม่',
                          },
                        ]}
                        name="BNumber1"
                      >
                        <Input
                          placeholder="08xxxxxxxx"
                          maxLength={10}
                          autoComplete="off"
                          name="BNumber1"
                          value={form.BNumber1}
                        />
                      </Form.Item>

                      <Form.Item name="BNumber2" normalize={(value) => value.replace(/[^\d]+/g, '')}>
                        <Input
                          placeholder="08xxxxxxxx"
                          maxLength={10}
                          autoComplete="off"
                          name="BNumber2"
                          value={form.BNumber2}
                        />
                      </Form.Item>

                      <Form.Item name="BNumber3" normalize={(value) => value.replace(/[^\d]+/g, '')}>
                        <Input
                          placeholder="08xxxxxxxx"
                          maxLength={10}
                          autoComplete="off"
                          name="BNumber3"
                          value={form.BNumber3}
                        />
                      </Form.Item>
                    </StyledSection>
                  </>
                )}

                <StyledSection>
                  <StyledTextLabel>หมายเลขติดต่อกลับอื่น *</StyledTextLabel>
                  <Form.Item
                    normalize={(value) => value.replace(/[^\d]+/g, '')}
                    rules={[
                      {
                        required: true,
                        message: 'กรุณากรอกหมายเลขติดต่อกลับอื่น',
                        whitespace: true,
                      },
                      {
                        pattern: new RegExp('^0[0-9]{9}$'),
                        message: 'ท่านกรอกข้อมูลไม่ถูกต้อง กรุณากรอกใหม่',
                      },
                    ]}
                    name="callBackNumber"
                  >
                    <Input
                      maxLength={10}
                      placeholder="08xxxxxxxx"
                      autoComplete="off"
                      name="callBackNumber"
                      value={form.callBackNumber}
                    />
                  </Form.Item>

                  <StyledTextLabel>ระบุอาการปัญหาเพิ่มเติม</StyledTextLabel>
                  <Form.Item name="problemMoreInfo">
                    <Input.TextArea
                      autoSize
                      placeholder="เช่น ระบุ Website หรือ Application ที่ใช้งานไม่ได้"
                      rows={2}
                      autoComplete="off"
                      name="problemMoreInfo"
                      value={form.problemMoreInfo}
                    />
                  </Form.Item>
                </StyledSection>
              </StyledSection>

              <StyledWrapButton>
                <div style={{ display: 'flex', width: '10em' }}>
                  <Button
                    style={{ backgroundColor: '#e7279b', color: '#fff', fontWeight: 'bold' }}
                    shape="round"
                    htmlType="submit"
                  >
                    ยืนยัน
                  </Button>
                </div>
              </StyledWrapButton>
            </Form>
          </StyledWrapFormContent>
        </StyledWrapContainer>
      </StyledWrapForm>
    )
  }
}
