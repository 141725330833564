import React, { Component } from 'react'
import { Spin, Image } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import SurveyFormModal from './surveyFormModal'
import { StyledWrapModal, StyledModal, StyledWrapWebview } from './style'
import { baseUrl } from '../../functions/baseUrl'
import { getSurvey, updateSurvey } from '../../services/survey'

export default class SurveyForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userId: this.props.userId || '',
      surveyCode: this.props.surveyCode || '',
      token: this.props.token || '',
      survey: null,
      loading: true,
      showModal: false,
      message: {text: '', success: false},
      baseUrl: baseUrl(),
    }
    this.formRef = React.createRef()
  }

  componentDidMount = () => {
    this.getSurvey()
  }

  getSurvey = () => {
    const channal = this.props.type !== 'modal' ? 'gomoFacebook' : null
    getSurvey(this.state.userId, this.state.surveyCode, channal)
      .then((res) => {
        if (res.statusCode === '20000') {
          this.setState({ survey: res.data, loading: false })
          this.onOpenSurvey(res.data)
        }
      })
      .catch((e) => {
        console.log(`"${JSON.stringify(e)}"`)
      })
  }

  onUpdateSurvey = (data) => {
    const channal = this.props.type !== 'modal' ? 'gomoFacebook' : null
    updateSurvey(data, this.state.token, channal)
      .then((res) => {
        if (res.statusCode === '20000') {
          console.log(`ISOPENSURVEY ${JSON.stringify(data)}`)
        } else {
          console.log(`ERROR_ISOPENSURVEY ${JSON.stringify(data)}`)
        }
      })
      .catch((e) => {
        console.log(`ERROR_ISOPENSURVEY ${JSON.stringify(e)}`)
      })
  }

  onOpenSurvey = (survey) => {
    const prepareData = survey.surveyData.map((ele) => {
      return {
        surveyId: survey?.surveyId || '',
        surveyType: ele?.surveyType || '',
        question: ele?.question || '',
        point: 0,
        status: 'open',
        commentText: '',
      }
    })

    const data = {
      userId: this.state.userId,
      intentTag: this.props.type === 'modal' ? this.props.profile?.userProfile?.intentTag : this.props.intentTag,
      surveys: prepareData,
    }
    this.onUpdateSurvey(data)
  }

  onFinish = (values) => {
    const data = {
      userId: this.state.userId,
      intentTag: this.props.type === 'modal' ? this.props.profile?.userProfile?.intentTag : this.props.intentTag,
      surveys: values,
    }
    const channal = this.props.type !== 'modal' ? 'gomoFacebook' : null
    updateSurvey(data, this.state.token, channal)
      .then((res) => {
        if (res.statusCode === '20000') {
          this.handleSuccess('success')
        } else {
          this.handleSuccess('error')
        }
      })
      .catch((e) => {
        this.handleSuccess('error')
        console.log(`"${JSON.stringify(e)}"`)
      })
  }

  handleSuccess = (type) => {
    if (this.props.type === 'modal') {
      this.props.closeModalSurvey(type, this.state.survey)
    } else {
      this.setState({
        message: {
          text:
            type === 'success'
              ? 'ขอบคุณคุณลูกค้าที่ช่วยประเมินการให้บริการของ GOMO ช่วยเป็นกำลังใจให้กันต่อไปนะคะ ครั้งหน้าอย่าลืมมาสมัครแพ็ก หรือถามข้อมูล กับ GOMO ได้ตลอด 24 ชั่วโมงค่ะ'
              : 'เกิดข้อผิดพลาดบางประการ',
          success: type === 'success' ? true : false,
        },
        showModal: true,
      })
    }
  }

  render() {
    const { showModal, message } = this.state
    return (
      <>
        {showModal ? (
          <>
            <div
              className="form-survey"
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '1em',
              }}
            >
             {this.state.survey?.thxData?.pic && <Image preview={false} style={{ width: '200px' }} src={this.state.survey?.thxData?.pic} />}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 21,
                  textAlign: 'center',
                  marginTop: '1em',
                  paddingLeft: '5em',
                  paddingRight: '5em'
                }}
              >
                {this.state.survey?.thxData?.text || message.text || ''}
              </div>
            </div>
          </>
        ) : (
          <div
            className="form-survey"
            style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {this.state.loading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color: '#403b89' }} spin />} />
            ) : (
              <div style={{ width: '100%' }}>
                {this.props.type === 'modal' && this.state?.survey ? (
                  <StyledWrapModal>
                    <StyledModal>
                      <SurveyFormModal
                        survey={this.state?.survey}
                        userId={this.props.userId}
                        formRef={this.formRef}
                        onFinish={this.onFinish}
                      />
                    </StyledModal>
                  </StyledWrapModal>
                ) : (
                  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <StyledWrapWebview>
                      <SurveyFormModal
                        survey={this.state?.survey}
                        userId={this.props.userId}
                        formRef={this.formRef}
                        onFinish={this.onFinish}
                      />
                    </StyledWrapWebview>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </>
    )
  }
}
