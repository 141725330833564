import styled from 'styled-components'



export const StyledWrapForm = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1.5em;
  flex-direction: column;
  overflow: auto;
  @media only screen and (max-width: 512px) {
    padding: 1em;
}
`

export const StyledHeader= styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`



export const StyledTextHeader = styled.div`
display: flex;
font-size: 26px;
justify-content: center;
margin-bottom: 0.5em;
text-decoration: underline;
`


export const StyledTextSubHeader = styled.div`
display: flex;
font-size: 20px;
word-wrap: break-word;
text-align: start;
margin-bottom: 0.5em;
`

export const StyledTextLabel = styled.div`
  display: flex;
  font-size: 20px;
  text-align: start;
`

export const StyledTextTopic = styled.div`
  display: flex;
  font-size: 20px;
  margin-top: 0.25em;
  margin-bottom: 0.1em;
`

export const StyledWrapButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2em;
`

export const StyledWrapImage = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  width: 110px;
  height: 110px;
  max-width: 110px;
  max-height: 110px;
  align-items: center;
  margin-bottom: 0.2em;
  cursor: pointer;
  padding: 5px;
  @media only screen and (max-width: 512px) {
    width: 90px;
    height: 90px;
    max-width: 90px;
    max-height: 90px;
}
`

export const StyledWrapImageList = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  flex-flow: wrap;
`

export const StyledWrapImageItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
`

export const StyledWrapInput = styled.div`
  display: flex;
  margin-top: 2em;
  flex-direction: column;
`

export const StyledWrapFooter = styled.div`
  display: flex;
  margin-top: 2em;
  flex-direction: column;
  width: 100%;
`

export const StyledWrapFooterImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5em;
`

export const StyledWrapFooterText = styled.div`
  display: flex;
  font-size: 20px;
  text-align: start;
`



