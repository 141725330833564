import styled from 'styled-components'


export const StyledWrapChat = styled.div`
pointer-events: auto;
  display: flex;
  background-color: #F1F1FF;
  width: ${props => props.width && props.fixed ? props.width : '100%'};
  height: ${props => props.height && props.fixed ? props.height : '100%'};
  border-top-right-radius: ${props => props.fixed ? '10px' : '0px'};
  border-top-left-radius: ${props => props.fixed ? '10px' : '0px'};
  flex-direction: column;
  position : relative;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  text-align: center;
  overflow: hidden;
  font-size: 22px;
  line-height: 1.3;
  overflow-wrap: anywhere;
@media only screen and (max-width: 480px) {
  width: 100%;
  height: 100%;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
`


export const StyledWrapTopbar = styled.div`
  display: flex;
  background-color: #403b89;
  width: 100%;
  height: ${props => props.fixed ? '2.3em' : '3em'}; 
  flex-direction: row;
  padding: 0.5em;
  position: absolute;
  top: 0;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  border-top-right-radius: ${props => props.fixed ? '10px' : '0px'};
  border-top-left-radius: ${props => props.fixed ? '10px' : '0px'};
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  @media only screen and (max-width: 480px) {
    height: 3em;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}
`

export const StyledWrapBottom = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  height: auto;
  padding: 0.5em;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  align-items: center;

`


export const StyledWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${props => !props.showTopBar ? '0.5em': props.fixed ? '3em' : '3.5em'};
  padding-bottom: 6em;
  overflow-y: auto;
  overflow-x: hidden;
@media only screen and (max-width: 480px) {
  padding-top: ${props => !props.showTopBar ? '0.5em':  '3.5em'};
}
`

export const StyledWrapButton = styled.div`
  display: flex;
  margin-left: 0.5em;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
`

export const StyledWrapTopbarButtonStart = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  flex-directions:row;
`

export const StyledWrapTopbarButtonEnd = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  flex-directions:row;
`

export const StyledWrapUpload = styled.div`
width: 35px;
height: 35px;
min-width: 35px;
min-height: 35px;
display:flex;
align-items: center;
margin-right: 0.5em;
cursor: pointer;
justify-content: center;
border: 1px solid #E4238F;
border-radius: 100%;
`

