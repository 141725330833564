import React, { Component } from 'react'
import { Input, Button, Image } from 'antd'
import {
  StyledHeader,
  StyledTextLabel,
  StyledTextHeader,
  StyledTextSubHeader,
  StyledWrapButton,
  StyledWrapImage,
  StyledWrapImageList,
  StyledWrapImageItem,
  StyledWrapInput,
  StyledWrapFooter,
  StyledWrapFooterImage,
  StyledWrapFooterText
} from './style'
import { getImageSrc } from '../../../functions'

export default class SurveyFormModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 0,
      answerList: [],
    }
  }

  componentDidMount = () => {
    if (this.props.survey && this.props.survey?.surveyData.length > 0) {
      const data = this.props.survey?.surveyData.map((ele, i) => {
        return {
          id: i,
          item: null,
          commentText: '',
          question: ele?.question,
          surveyType: ele?.surveyType
        }
      })
      this.setState({ answerList: data })
    }
  }

  submitStep = () => {
    const prepareData = this.state.answerList.map((ele) => {
      return {
        surveyId: this.props.survey.surveyId || '',
        surveyType: ele?.surveyType || '',
        question: ele?.question || '',
        point: ele?.item?.point || 0,
        status: 'complete',
        commentText: ele.commentText || '',
      }
    })
    this.props.onFinish(prepareData)
  }

  onSelectImage = (item, index) => {
    const newData = this.state.answerList.map((ele, i) => {
      if (i === index) {
        return { ...ele, item: item, commentText: item?.comment ? ele.commentText : '' }
      } else {
        return { ...ele }
      }
    })
    this.setState({ answerList: newData })
  }

  onCommentData = (text, index) => {
    const newData = this.state.answerList.map((ele, i) => {
      if (i === index) {
        return { ...ele, commentText: text }
      } else {
        return { ...ele }
      }
    })
    this.setState({ answerList: newData })
  }

  renderSurveyList = (surveyData) => {
    const list = surveyData?.map((ele, i) => {
      const isDisabled =
      (ele.require && !this.state.answerList[i]?.item) ||
        (this.state.answerList[i]?.item?.comment && !this.state.answerList[i]?.commentText)

      if (i === this.state.step) {
        return (
          <>
            {ele?.question && <StyledTextSubHeader>{ele?.question} </StyledTextSubHeader>}
            <StyledWrapImageList key={i}>
              {ele.surveyObj.map((ele, index) => {
                return (
                  <>
                    <StyledWrapImageItem key={index}>
                      <StyledWrapImage
                        style={{ border: this.state.answerList[i]?.item === ele ? '2px solid #e7279b' : 'none' }}
                        onClick={() => this.onSelectImage(ele, i)}
                      >
                        <Image className='survey-img' preview={false} src={`${ele.img || getImageSrc(`image/gomo-survey-${index}.png`)}`} />
                      </StyledWrapImage>
                      <StyledTextLabel>{ele.rate}</StyledTextLabel>
                    </StyledWrapImageItem>
                  </>
                )
              })}
            </StyledWrapImageList>
            {this.state.answerList[i]?.item?.comment && (
              <>
                <StyledWrapInput>
                  <StyledTextLabel>
                    ช่วยพิมพ์เรื่องที่ต้องการให้ GOMO ปรับปรุง
                    เพื่อให้คุณลูกค้าพึงพอใจมากที่สุดในการใช้บริการครั้งถัดไปค่ะ
                  </StyledTextLabel>
                  <Input.TextArea
                    placeholder={this.state.answerList[i]?.item?.commentText || 'กรุณากรอกข้อมูล'}
                    rows={2}
                    autoComplete="off"
                    onChange={(e) => this.onCommentData(e.target.value, i)}
                    value={this.state.answerList[i]?.commentText || ''}
                  />
                </StyledWrapInput>
              </>
            )}
            <StyledWrapButton>
              <div style={{ display: 'flex' }}>
                {this.state.step > 0 && (
                  <Button
                    style={{
                      backgroundColor: '#000',
                      fontWeight: 'bold',
                      color: 'white',
                      width: '7em',
                      fontSize: 20,
                      height: '45px',
                      marginRight: 15,
                    }}
                    shape="round"
                    onClick={() => {
                      this.setState({ step: this.state.step - 1 })
                    }}
                  >
                    ย้อนกลับ
                  </Button>
                )}
                <Button
                  style={{
                    backgroundColor: isDisabled ? '#ccc' : '#e7279b',
                    fontWeight: 'bold',
                    color: 'white',
                    width: '7em',
                    fontSize: 20,
                    height: '45px',
                  }}
                  shape="round"
                  disabled={isDisabled}
                  onClick={() => {
                    if (this.state.step >= surveyData?.length - 1) {
                      this.submitStep()
                    } else {
                      this.setState({ step: this.state.step + 1 })
                    }
                  }}
                >
                  {this.state.step >= surveyData?.length - 1 ? 'ยืนยันให้คะแนน' : 'ต่อไป'}
                </Button>
              </div>
            </StyledWrapButton>
              {(this.props.survey?.footerText?.img || this.props.survey?.footerText?.text) && <StyledWrapFooter>
            {this.props.survey?.footerText?.img && <StyledWrapFooterImage>
            <Image style={{width: 'auto'}} height={120} width={'fit-content'} preview={false} src={this.props.survey.footerText.img} />
            </StyledWrapFooterImage>}
           {this.props.survey?.footerText?.text && <StyledWrapFooterText>
                  {this.props.survey?.footerText?.text || ''}
              </StyledWrapFooterText>}
              </StyledWrapFooter>}
          </>
        )
      } else {
        return null
      }
    })

    return list
  }

  render() {
    const { survey } = this.props
    return (
      <>
        <StyledHeader>
          <StyledTextHeader>{survey?.headText ? survey?.headText : 'แบบประเมินความพึงพอใจ'}</StyledTextHeader>
        </StyledHeader>
        {survey?.surveyData.length > 0 && this.renderSurveyList(survey?.surveyData)}
      </>
    )
  }
}
