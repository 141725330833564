import styled from 'styled-components'



export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  padding: 1em 0.5em;
`

export const StyledLine = styled.div`
  display: flex;
  height: 1px;
  width: 100%;
  background-color: grey;
  margin: 0.5em;
`

export const StyledText = styled.div`
  color: grey;
  display: flex;
  font-size: 19px;
  white-space: nowrap;
`