import React from 'react'
import { Avatar, Image } from 'antd'
import { checkDate } from '../../functions/checkDate'
import {
  StyledWrapSender,
  StyledWrapReceiver,
  StyledMessageTextReceiver,
  StyledMessageTextSender,
  StyledMessageDialogReceiver,
  StyledWrapAvatar,
  StyledFlexColumn,
  StyledContainer,
  StyledContainerDialog,
  StyledTextUser,
  StyledTextTimestamp,
  StyledButton,
  StyledWrapSenderTimestamp,
  StyledFlexRow,
  StyledMessageImage,
  StyledMessageImageSender
} from './style'

import { getImageSrc } from '../../functions/getImageSrc'

const contentClickHandler = (e, props) => {
  const targetLink = e.target.closest('a')
  if (!targetLink) return
  e.preventDefault()
  if (props.onSendMessage && e.target.id === 'clickPayload' && e.target.getAttribute('payload') ) {
    props.onSendMessage({text: e.target.getAttribute('payload')})
  } else {
    window.open(targetLink.href, "_blank")
  }
}

const replaceStr = (val) => {
  if (typeof val.replaceAll == 'undefined') {
    return val.replace(
      new RegExp('<img', 'g'),
      () => '<img class="ie-image-width" style="max-width:100%;max-height:250px;border-radius:15px;"',
    )
  } else {
    return val.replaceAll('<img', '<img class="ie-image-width" style="max-width:100%;max-height:250px;border-radius:15px;"')
  }
}

const renderItemDialog = (data, props) => {
  switch (data.type) {
    case 'text':
      return <MessageTextReceiver className="ie-inline-dialog" data={data} />
    case 'template':
      return (
        <MessageTemplate
          className="ie-inline-dialog"
          data={data}
          fixed={props.fixed}
          onClickDialog={props.onClickDialog}
        />
      )
    case 'image':
      return <MessageImage className="ie-inline-dialog" data={data} fixed={props.fixed} />

    default:
      break
  }
}

export const RenderAvatar = () => (
  <>
    <Avatar size="large" src={<Image preview={false} src={getImageSrc('image/gomo-avatar.png')} />} />
  </>
)

export const MessageWrapReceiver = (props) => (
  <StyledWrapReceiver className="ie-inline">
    <StyledContainer fixed={props.fixed}>
      <StyledWrapAvatar>{RenderAvatar()}</StyledWrapAvatar>
      <StyledFlexColumn className="ie-wrap-image">
        <StyledTextUser>{props.data.userName}</StyledTextUser>
        {props.children}
        <StyledTextTimestamp>{checkDate(props.data.timestamp)}</StyledTextTimestamp>
      </StyledFlexColumn>
    </StyledContainer>
  </StyledWrapReceiver>
)

export const MessageWrapSender = (props) => (
  <StyledWrapSender className="ie-inline">
    <StyledContainer fixed={props.fixed}>
      <StyledFlexColumn className="ie-wrap-image" sender>
        {props.children}
        <StyledWrapSenderTimestamp>
          <StyledTextTimestamp sender>Read {checkDate(props.data.timestamp)}</StyledTextTimestamp>
          <Avatar
            style={{ marginLeft: '0.3em' }}
            size={20}
            src={<Image preview={false} src={getImageSrc('image/userIcon.png')} />}
          />
        </StyledWrapSenderTimestamp>
      </StyledFlexColumn>
    </StyledContainer>
  </StyledWrapSender>
)

export const MessageTextReceiver = (props) => (
  <StyledMessageTextReceiver className={props.className}>
    <div onClick={(e) => contentClickHandler(e, props)} dangerouslySetInnerHTML={{ __html: replaceStr(props.data.text) }} />
  </StyledMessageTextReceiver>
)

export const MessageTextSender = (props) => <StyledMessageTextSender>{props.data.text}</StyledMessageTextSender>

export const MessageTextSenderLoadingImage = (props) => (
<StyledMessageTextSender style={{flexDirection: 'row', display:'flex'}}>
  <div>{props.data.text}</div>
<div className="spinner-box">
<div className="pulse-container">  
  <div className="pulse-bubble pulse-bubble-1"></div>
  <div className="pulse-bubble pulse-bubble-2"></div>
  <div className="pulse-bubble pulse-bubble-3"></div>
</div>
</div>
</StyledMessageTextSender>
)

export const MessageDialog = (props) => (
  <>
    <StyledWrapReceiver className="ie-inline">
      <StyledContainerDialog fixed={props.fixed}>
        <StyledFlexColumn>
          <div style={{ marginBottom: '-0.6em' }}>
            <StyledFlexRow>
              <StyledWrapAvatar>{RenderAvatar()}</StyledWrapAvatar>
              <StyledTextUser>{props.data.userName}</StyledTextUser>
            </StyledFlexRow>
          </div>
          {props.data?.messages.length > 0 &&
            props.data?.messages?.map((ele, i) => {
              if (ele.type === 'choice') {
                return (
                  <div style={{ width: '100%' }} key={i}>
                    <StyledFlexRow>
                      <StyledWrapAvatar />
                      <StyledMessageTextReceiver className="ie-inline-dialog">
                        <div onClick={(e) => contentClickHandler(e, props)} dangerouslySetInnerHTML={{ __html: ele.title }} />
                      </StyledMessageTextReceiver>
                    </StyledFlexRow>
                    <div style={{ marginTop: '0.3em' }}>
                      {ele.choices?.map((item, i) => {
                        return (
                          <StyledMessageDialogReceiver
                            className="ie-inline-dialog-choice"
                            positionLast={i === ele.choices.length - 1 ? true : false}
                            positionFirst={i === 0 ? true : false}
                            onClick={() => props.onClickDialog(item.payload, item.title)}
                            key={i}
                          >
                            {item.title}
                            <Image preview={false} src={getImageSrc('image/right-arrow.svg')} />
                          </StyledMessageDialogReceiver>
                        )
                      })}
                    </div>
                  </div>
                )
              } else {
                return (
                  <div style={{ width: '100%' }} key={i}>
                    <StyledFlexRow>
                      <StyledWrapAvatar />
                      {renderItemDialog(ele, props)}
                    </StyledFlexRow>
                  </div>
                )
              }
            })}
          <StyledFlexRow>
            {props.data?.messages[props.data?.messages.length - 1].type !== 'choice' && <StyledWrapAvatar />}
            <StyledTextTimestamp>{checkDate(props.data.timestamp)}</StyledTextTimestamp>
          </StyledFlexRow>
        </StyledFlexColumn>
      </StyledContainerDialog>
    </StyledWrapReceiver>
  </>
)

const handleRedirectUrl = (props) => {
  const url = props.data?.template?.actions[0].url[0]
  const split = url.split('.')
  const type = split[split.length - 1]
  if (type === 'pdf' && /Android/i.test(navigator.userAgent)) {
    props.onOpenPdf(url)
  } else {
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
  }
}

export const MessageTemplate = (props) => (
  <>
    <StyledMessageTextReceiver className={props.className}>
      <div onClick={(e) => contentClickHandler(e, props)} dangerouslySetInnerHTML={{ __html: props.data?.template?.title }} />
      <StyledButton
        onClick={() =>
          props.data?.template?.actions[0].type === 'postback'
            ? props.onClickDialog(props.data?.template?.actions[0].payload, props.data?.template?.actions[0].text)
            : handleRedirectUrl(props)
        }
      >
        <div dangerouslySetInnerHTML={{ __html: props.data?.template?.actions[0].text }} />
      </StyledButton>
    </StyledMessageTextReceiver>
  </>
)

export const MessageImage = (props) => (
  <>
    <StyledMessageImage className={`${props.className} ie-image`}>
      <div
        style={{ display: 'flex', justifyContent: 'flex-start' }}
        onClick={(e) => contentClickHandler(e, props)}
        dangerouslySetInnerHTML={{
          __html: replaceStr(props.data?.text),
        }}
      />
    </StyledMessageImage>
  </>
)

export const MessageImageSender = (props) => (
  <>
    <StyledMessageImageSender className={`${props.className} ie-image`}>
      <div
        style={{ display: 'flex', justifyContent: 'flex-start' }}
        onClick={(e) => contentClickHandler(e, props)}
        dangerouslySetInnerHTML={{
          __html: replaceStr(props.data?.text),
        }}
      />
    </StyledMessageImageSender>
  </>
)

export const MessageCondition = (props) => (
  <StyledMessageTextReceiver className={props.className}>
    <div
      onClick={(e) => contentClickHandler(e, props)}
      dangerouslySetInnerHTML={{
        __html: props.data?.text,
      }}
    />
    {props.active && (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '10px',
          marginBottom: '5px',
        }}
      >
        <div
          style={{
            backgroundColor: '#e4238f',
            color: 'white',
            fontSize: '19px',
            width: '45%',
            height: 40,
            borderRadius: 20,
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
          onClick={() => props.onClickDialog('ใช่')}
        >
          ใช่
        </div>
        <div
          style={{
            backgroundColor: 'black',
            color: 'white',
            fontSize: '19px',
            width: '45%',
            height: 40,
            borderRadius: 20,
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
          onClick={() => props.onClickDialog('ไม่ใช่')}
        >
          ไม่ใช่
        </div>
      </div>
    )}
  </StyledMessageTextReceiver>
)

export const MessageConditionSurvey = (props) => (
  <StyledMessageTextReceiver className={props.className}>
    <div
      dangerouslySetInnerHTML={{
        __html: props.text,
      }}
    />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '10px',
          marginBottom: '5px',
        }}
      >
        <div
          style={{
            backgroundColor: '#e4238f',
            color: 'white',
            fontSize: '19px',
            width: '45%',
            height: 40,
            borderRadius: 20,
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
          onClick={() => props.onClickDialog('ครบ')}
        >
          ครบ
        </div>
        <div
          style={{
            backgroundColor: 'black',
            color: 'white',
            fontSize: '19px',
            width: '45%',
            height: 40,
            borderRadius: 20,
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
          onClick={() => props.onClickDialog('ไม่ครบ')}
        >
          ไม่ครบ
        </div>
      </div>
  </StyledMessageTextReceiver>
)