const dayjs = require('dayjs')

const range = (start, end) => {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}

export const checkDate = (date) => {
  return dayjs(date).format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY')
    ? dayjs(date).format('HH:mm')
    : dayjs(date).format('DD/MM/YYYY.HH:mm')
}

export const getDisabledTime = (hour, minute) => {
  return {
    disabledHours: () => range(Number(hour) + 1, 24),
    disabledMinutes: () => range(Number(minute) + 1, 60),
  }
}

export const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const months_th = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
]

export const getPrevious6MonthsWithYear = (currentDate) => {
  let result = []
  currentDate.setMonth(currentDate.getMonth() - 6) // previous 6 months

  for (let i = 0; i <= 6; i++) {
    let monthIndex = currentDate.getMonth()
    let monthName = month[currentDate.getMonth()]
    let monthTh = months_th[monthIndex] // Get the Thai month name
    let year = currentDate.getFullYear()
    result.push({ monthTh, month: monthName, year: year, type: ['ซื้อแพ็กเกจ', 'ซื้อเหรียญ (Coin)'] })
    currentDate.setMonth(currentDate.getMonth() + 1)
  }
  return result
}
