
import React from 'react'

import {
    StyledContainer,
    StyledLine,
    StyledText,
  } from './style'
  

export const SectionLine = (props) => (
    <>
    <StyledContainer>
      <StyledLine />
          <StyledText>{props.date}</StyledText>
      <StyledLine />
      </StyledContainer>
    </>
  )