import styled from 'styled-components'


//message text

export const StyledWrapReceiver = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  justify-content: flex-start;
`

export const StyledWrapSender = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
  justify-content: flex-end;
`

export const StyledMessageTextReceiver = styled.div`
  display: inline-block;
  background-color: #fff;
  height: auto;
  border-radius: 15px;
  border-top-left-radius: 0px;
  padding: 0.4em 0.7em;
  text-align: start;
  overflow-wrap: anywhere !important;
  width: 100%;
  max-width: 100%;
  font-size: 19px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  align-items: center;
  margin: 0.2em 0em;
  text-align: left;
  word-wrap: break-word;
`

export const StyledMessageTextSender = styled.div`
display: inline-block;
  background-color: #e4238f;
  height: auto;
  border-radius: 15px;
  border-bottom-right-radius: 0px;
  padding: 0.4em 0.7em;
  text-align: start;
  overflow-wrap: anywhere !important;
  color: #fff;
  width: fit-content;
  max-width: 100%;
  font-size: 19px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  align-items: center;
  text-align: left;
  word-wrap: break-word;
  word-break: keep-all;
`


export const StyledWrapSenderTimestamp = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
`

//message dialog



export const StyledMessageDialogReceiver = styled.div`
  display: flex;
  background-color: #403b89;
  color: #fff;
  height: auto;
  width: 100%;
  border-top-left-radius: ${props => props.positionFirst ? '15px' : '0px'};
  border-top-right-radius: ${props => props.positionFirst  ? '15px' : '0px'};
  border-bottom-left-radius: ${props => props.positionLast ? '15px' : '0px'};
  border-bottom-right-radius: ${props => props.positionLast ? '15px' : '0px'};
  padding: 0.4em 0.7em;
  border-color: #fff;
  text-align: start;
  overflow-wrap: anywhere !important;
  font-size: 19px;
  align-items: center;
    justify-content: space-between;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 1px;
  cursor: pointer;
  word-wrap: break-word;
  white-space: break-spaces
`



export const StyledWrapAvatar = styled.div`
  display: flex;
  margin-right: 0.5em;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
`

export const StyledTextUser = styled.div`
  display: flex;
  font-size: 19px;
  color: #5e656d;
`

export const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${props => props.sender ? 'flex-end' : 'flex-start'};
`

export const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: ${props => !props.fixed ? '50%' : '100%'}; 
  margin:${props => !props.fixed ? '0em 1em' : '0em 0.7em'};  
  @media only screen and (max-width: 480px) {
    max-width: 100%;
    margin: 0em 0.7em;
}
`

export const StyledContainerDialog = styled.div`
  display: flex;
  flex-direction: row;
  width: ${props => !props.fixed ? '50%' : '100%'}; 
  margin: ${props => !props.fixed ? '0em 1em' : '0em 0.7em'};  
  @media only screen and (max-width: 480px) {
    width: 100%;
    margin: 0em 0.7em;
}
`

export const StyledTextTimestamp = styled.div`
  display: flex;
  font-size: 16px;
    margin-top: 0.5em;
    color: gray;
    justify-content: ${props => props.sender ? 'flex-end' : 'flex-start'};
`


export const StyledButton = styled.div`
display: flex;
font-size: 19px;
height: auto;
border-radius: 1em;
padding: 0.4em 0.7em;
  text-align: center;
width: auto;
color: #fff;
background-color: #e4238f;
justify-content: center;
margin: 0.5em;
min-width: 70%;
cursor: pointer;
`

export const StyledWrapCarousel = styled.div`
display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 0.5em;
  ::-webkit-scrollbar {
    background: transparent;
    height: 0;
}
`

export const StyledCarousel = styled.div`
display: flex;
background-color: #fff;
margin: 0em 0.25em;
padding: 0.4em 0.7em;
border-radius: 1em;
font-size: 19px;
min-height: 5em;
    min-width: 5em;
    max-height: 5em;
    max-width: 5em;
    white-space: pre-wrap;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
`


export const StyledMessageImage = styled.div`
  display: flex;
  height: auto;
  text-align: start;
  overflow-wrap: anywhere;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0.2em 0em;
  justify-content: flex-start;
`

export const StyledMessageImageSender = styled.div`
  display: flex;
  height: auto;
  text-align: start;
  overflow-wrap: anywhere;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0.2em 0em;
  justify-content: flex-end;
`