import React, { Component } from 'react'
import { Input, Button, Form, Image } from 'antd'
import {
  StyledWrapChat,
  StyledWrapTopbar,
  StyledWrapBottom,
  StyledWrapContent,
  StyledWrapButton,
  StyledWrapTopbarButtonStart,
  StyledWrapTopbarButtonEnd,
  StyledWrapUpload
} from './style'
import { Suggestion } from '../index'
import {getImageSrc} from '../../functions/getImageSrc'
import {PaperClipOutlined} from '@ant-design/icons'

export default class LiveChat extends Component {
  formRef = React.createRef()
  fileUpload = React.createRef()

  componentDidMount = () => {}

  onFinish = (value) => {
    if (value.text.trim()) {
      this.props.onSendMessage(value)
    }
    this.formRef.current.resetFields()
    this.textInput.focus()
  }

  


  render() {
    const props = this.props
    return (
      <StyledWrapChat fixed={props.fixed} width={props.width} height={props.height}>
        {props.showTopBar && <StyledWrapTopbar fixed={props.fixed}>
          <StyledWrapTopbarButtonStart onClick={props.goBack}>
          </StyledWrapTopbarButtonStart>
          <div>{props.headerText || ''}</div>
          <StyledWrapTopbarButtonEnd onClick={props.hideLiveChat}>
            {props.fixed && <Image preview={false} src={getImageSrc('image/close.svg')} style={{ width: '15px', cursor:'pointer' }} />}
          </StyledWrapTopbarButtonEnd>
        </StyledWrapTopbar>}
        <StyledWrapContent id='targetElement' showTopBar={props.showTopBar} fixed={props.fixed} className='scroll ie-inline' >
          {props.children}
          <div className='bottom-flex'></div>
        </StyledWrapContent>
   
        <Suggestion blockQuickReply={props.blockQuickReply} onClick={(e) => this.onFinish(e)} data={props.suggestionItem} />

        <div className="livechat">
          <StyledWrapBottom>
            <StyledWrapUpload id='uploadButton' style={{borderColor: !props.loadingUploadFile ? '#E4238F' : '#ffcfea', cursor :!props.loadingUploadFile ? 'pointer' : 'not-allowed' }} onClick={() => !props.loadingUploadFile && this.fileUpload.current.click()} >
            <PaperClipOutlined style={{color: !props.loadingUploadFile ? '#E4238F' : '#ffcfea'}} />
            <input onChange={(e) => {
              this.props.onUploadFile(e.target.files[0])
              this.fileUpload.current.value = null
              }} type='file' id='fileUpload' accept=".jpg,.jpeg,.png" ref={this.fileUpload} style={{display: 'none'}}/>
            </StyledWrapUpload>
            <Form
              style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}
              ref={this.formRef}
              layout="inline"
              onFinish={(e) => this.onFinish(e)}
              autoComplete="off"
            >
              <Form.Item style={{ flex: 1 }} name="text" size="large">
                <Input
                  onFocus={() => props.checkStatusBlockQuickReply()}
                  ref={(input) => {
                    this.textInput = input
                  }}
                  placeholder="พิมพ์ข้อความมาได้เลย"
                  size="large"
                />
              </Form.Item>

              <StyledWrapButton>
                <Form.Item>
                  <Button
                    className="button-ie"
                    style={{display:'flex', alignItems: 'center', justifyContent:'center'}}
                    type="text"
                    icon={<Image preview={false} src={getImageSrc('image/arrow.svg')} style={{ width: '35px' }} />}
                    htmlType="submit"
                  ></Button>
                </Form.Item>
              </StyledWrapButton>
            </Form>
          </StyledWrapBottom>
        </div>
      </StyledWrapChat>
    )
  }
}
