import styled from 'styled-components'


export const StyledWrapForm = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  padding: 2em 10em;
  @media only screen and (max-width: 768px) {
    padding: 2em 0em;
}
`

export const StyledHeader= styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 1em;
  flex-direction: column;
`

export const StyledWrapContainer= styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 2em;
  flex-direction: column;
  border-top: 10px solid #403b89;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  @media only screen and (max-width: 768px) {
    border-left: 0px solid lightgrey;
    border-right: 0px solid lightgrey;
    border-bottom: 0px solid lightgrey;
}
  @media only screen and (max-width: 480px) {
    padding: 1em 1em;
}
`

export const StyledWrapFormContent= styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 2em;
  flex-direction: column;
  @media only screen and (max-width: 480px) {
    padding: 1em;
}
`

export const StyledSection= styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${props => props.width ? props.width : '45%'};
  height: auto;
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
  @media only screen and (max-width: 480px) {
    width: 100%;
}
`




export const StyledTextHeader = styled.div`
display: flex;
font-size: 36px;
font-weight: bold;
justify-content: center;
color: #e7279b;
margin-bottom: 0.5em;
@media only screen and (max-width: 480px) {
  font-size: 30px;
}
`


export const StyledTextSubHeader = styled.div`
display: flex;
font-size: 26px;
padding-bottom: 0.5em;
border-bottom: 1px solid lightgrey;
@media only screen and (max-width: 480px) {
  font-size: 20px;
}
`

export const StyledTextLabel = styled.div`
  display: flex;
  font-size: 21px;
`

export const StyledTextTopic = styled.div`
  display: flex;
  font-size: 21px;
  font-weight: bold;
  margin-top: 0.25em;
  margin-bottom: 0.1em;
`

export const StyledWrapButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2em;
`





