import React, { Component } from 'react'

import {
  MessageDialog,
  MessageTextReceiver,
  MessageTextSender,
  MessageTemplate,
  MessageImage,
  SectionLine,
  MessageWrapSender,
  MessageWrapReceiver,
  MessageCondition,
  MessageConditionSurvey,
  MessageImageSender,
  MessageTextSenderLoadingImage,
} from '../component'
import LiveChat from '../component/liveChat'
import ModalGomo from '../component/modalGomo'
import PDF from '../component/pdf'
import InternetProblemForm from '../component/internetProblemForm'
import VoiceProblemForm from '../component/voiceProblemForm'
import SurveyForm from '../component/surveyForm'
import socketIOClient from 'socket.io-client'
import { Image } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'

import { encryptedData, decryptedData } from '../functions/encryptData.js'
import { getGreeting, checkProfileType, uploadFile, encryptMobileNo, decryptMessage } from '../services/gomo'
import { getImageSrc } from '../functions/getImageSrc'
import { baseUrl } from '../functions/baseUrl'
import imageCompression from 'browser-image-compression'

const dayjs = require('dayjs')
const isWebview = require('is-ua-webview')

let socket

class Gomo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openChat: false,
      userId: null,
      message: [],
      suggestionItem: [
        { text: 'แพ็กเกจ GOMO', quickReply: true },
        { text: 'ย้ายค่ายเบอร์เดิม', quickReply: true },
        { text: 'เช็กสถานะย้ายค่าย', quickReply: true },
        { text: 'วิธีการชำระเงิน', quickReply: true },
        { text: 'แจ้งปัญหาสัญญาณ', quickReply: true },
        { text: 'เครื่องหาย/เปลี่ยนซิม', quickReply: true },
      ],
      showModalGomo: false,
      showModalForm: false,
      showModalSurvey: false,
      surveyCode: null,
      surveyToken: null,
      showModalFormType: '',
      mobileNo: null,
      isActiveFormProblem: false,
      date: dayjs().format('DD/MM/YYYY'),
      profile: null,
      count: 0,
      countQuickReply: 0,
      countCheckGomoNumber: 0,
      block: { status: false, timestamp: null },
      showTopBar: false,
      baseUrl: baseUrl(),
      isWaitingAgent: false,
      isTransfer: false,
      openPdf: false,
      pdfData: null,
      messageWaitingTransfer: [
        'GOMO Agent จะให้บริการคุณลูกค้าตามลำดับโดยจะตรวจสอบจากเบอร์และข้อมูลที่คุณลูกค้าพิมพ์เข้ามาค่ะ',
        'รอสักครู่นะคะ อย่าเพิ่งปิดหน้าจอหนีเราไปไหนน๊า',
        'เนื่องจากมีผู้เข้ามาใช้บริการเป็นจำนวนมาก อาจใช้ระยะเวลาอีกสักหน่อย อย่าเพิ่งปิดหน้าจอนะคะ GOMO Agent จะเข้ามาดูแลคุณลูกค้าอย่างแน่นอนค่ะ',
      ],
      mbtoken: null,
      subChannel: null,
      loadingUploadFile: false,
      msgGomoBcWc: '',
    }
  }

  messagesEndRef = React.createRef()

  componentDidMount = () => {
    this.checkTopBar()
    if (!this.props.fixed) {
      this.getUserId()
    }
  }

  componentWillUnmount = () => {
    if (this.state.userId) {
      socket.off(this.state.userId)
    }
    socket.removeAllListeners()
    clearInterval(this.isWaitingAgentInterval)
    clearTimeout(this.timer)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.openChat === true && prevState.openChat === false) {
      document.getElementById('targetElement').scrollTop = document.getElementById('targetElement').scrollHeight + 1000
    }
  }

  checkPushMessage = async () => {
    const param = new URLSearchParams(this.props?.location?.search).get('q')
    if (param) {
      const data = {
        dqs: param,
      }
      decryptMessage(data)
        .then((res) => {
          if (res.statusCode === '20000') {
            if (res.data.source === 'shortlink') {
              const newItem = {
                messages: [{ text: res.data.msg, type: 'text' }],
                position: 'sender',
                timestamp: new Date(),
              }
              this.setState({ message: [...this.state.message, newItem], subChannel: res.data.subChannel })
              this.sendMessageToSMM('message', res.data.msg)
            }
          }
        })
        .catch((e) => {
          console.log(`"${JSON.stringify(e)}"`)
        })
    }
  }

  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    })
    this.timer = setTimeout(() => {
      document.getElementById('targetElement').scrollTop = document.getElementById('targetElement').scrollHeight + 1000
    }, 300)
  }

  checkTopBar = () => {
    const webview = isWebview(navigator.userAgent)
    if (webview || navigator.platform === 'iPad' || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) {
      this.setState({ showTopBar: false })
    } else {
      this.setState({ showTopBar: true })
    }
  }

  getUserId = () => {
    getGreeting()
      .then((res) => {
        if (res.statusCode === '20000') {
          if (res.data.messages.length > 0) {
            const newMessage = {
              ...res.data,
              position: 'receiver',
              userName: 'GOMO Customer Service',
              timestamp: new Date(),
            }
            const group = this.state.message
            const newGroup = group.concat([newMessage])
            this.setState({ message: newGroup })
          }

          this.setState({
            showModalGomo: this.props.fixed ? true : false,
            userId: res.data.userId,
            profile: res.data.profile,
          })
          socket = socketIOClient(this.state.baseUrl, {
            path: '/api/socket.io',
            transports: ['websocket', 'polling'],
            query: { action: 'chat', uuid: res.data.userId },
            withCredentials: true,
            auth: {
              requestId: `CHATBOTEXTERNAL${res.data.userId}`,
            },
          })
          for (let index = 0; index < res.data.messages.length; index++) {
            const element = res.data.messages[index]
            this.sendMessageToSMM('greeting', element.text)
          }
          this.checkPushMessage()
          this.response()
        } else {
          const uuid = uuidv4()
          this.setState({
            showModalGomo: this.props.fixed ? true : false,
            userId: uuid,
          })
          socket = socketIOClient(this.state.baseUrl, {
            path: '/api/socket.io',
            transports: ['websocket', 'polling'],
            query: { action: 'chat', uuid: uuid },
            withCredentials: true,
            auth: {
              requestId: `CHATBOTEXTERNAL${uuid}`,
            },
          })
          this.checkPushMessage()
          this.response()
        }
      })
      .catch((e) => {
        const uuid = uuidv4()
        this.setState({
          showModalGomo: this.props.fixed ? true : false,
          userId: uuid,
        })
        socket = socketIOClient(this.state.baseUrl, {
          path: '/api/socket.io',
          transports: ['websocket', 'polling'],
          query: { action: 'chat', uuid: uuid },
          withCredentials: true,
          auth: {
            requestId: `CHATBOTEXTERNAL${uuid}`,
          },
        })
        this.checkPushMessage()
        this.response()
      })
  }

  getErrorMessage = (type) => {
    switch (type) {
      case 'default':
        return {
          messages: [{ type: 'text', text: 'คุณลูกค้าต้องการสอบถามเรื่องอะไรดีจ๊ะ ลองพิมพ์ข้อความสั้นๆกระชับๆดูจ้า' }],
          position: 'receiver',
          userName: 'GOMO Customer Service',
          timestamp: new Date(),
        }

      case 'count':
        return {
          messages: [
            {
              type: 'text',
              text: 'ขออภัยหากเราไม่สามารถช่วยเหลือคุณลูกค้าได้ เราขอส่งให้ GOMO Agent ดูแลต่อ รอสักครู่นะคะ',
            },
          ],
          position: 'receiver',
          userName: 'GOMO Customer Service',
          timestamp: new Date(),
        }

      case 'block':
        return {
          messages: [
            {
              type: 'text',
              text: 'คุณลูกค้าสามารถกดเลือกจากเมนูหรือพิมพ์เรื่องที่ต้องการสอบถาม และรอคำตอบสักครู่ โกโม่ยินดีให้บริการค่ะ และทางเราขออนุญาตงดให้บริการชั่วคราว หากพบการกระทำที่ไม่เหมาะสมค่ะ',
            },
          ],
          position: 'receiver',
          userName: 'GOMO Customer Service',
          timestamp: new Date(),
        }

      case 'wrongGomoNumber':
        return {
          messages: [
            {
              type: 'text',
              text: 'ขออภัยค่ะเราไม่สามารถทำรายการให้ได้เนื่องจาก คุณลูกค้าใส่หมายเลข GOMO ไม่ถูกต้องเกินจำนวนครั้งที่กำหนดค่ะ',
            },
          ],
          position: 'receiver',
          userName: 'GOMO Customer Service',
          timestamp: new Date(),
        }

      case 'transfer':
        return {
          messages: [
            {
              type: 'text',
              text: 'กรุณารอสักครู่ GOMO Agent กำลังเข้ามาดูแลคุณค่ะ',
            },
          ],
          position: 'receiver',
          userName: 'GOMO Customer Service',
          timestamp: new Date(),
        }

      case 'imageOverSize':
        return {
          messages: [
            {
              type: 'text',
              text: 'ขนาดรูปไม่เกิน 10MB!',
            },
          ],
          position: 'receiver',
          userName: 'GOMO Customer Service',
          timestamp: new Date(),
        }

      case 'imageWrongType':
        return {
          messages: [
            {
              type: 'text',
              text: 'ระบบจะรองรับไฟล์ .jpg/.jpeg/.png ที่มีขนาดไม่เกิน 10MB เท่านั้นนะคะ หากคุณลูกค้าใช้ iPhone, iPad สามารถดูวิธีการเปลี่ยนรูปแบบไฟล์ <a target="_blank" href="https://askaunjai.cloud.ais.th/aunjai-get-files/engine/manageFile/5f7613a8a474c90441fc0f6b/42d1b77079cad3816f51dd56956d0ed3.png">กดที่นี่เลยค่ะ</a>',
            },
          ],
          position: 'receiver',
          userName: 'GOMO Customer Service',
          timestamp: new Date(),
        }

      default:
        return {
          messages: [{ type: 'text', text: 'เกิดข้อผิดพลาดบางประการ' }],
          position: 'receiver',
          userName: 'GOMO Customer Service',
          timestamp: new Date(),
        }
    }
  }

  checkStatusBlockQuickReply = () => {
    const currentTime = dayjs()
    if (currentTime.diff(this.state.block?.timestamp, 'minute') >= 10) {
      this.setState({ block: { status: false, timestamp: null }, countQuickReply: 0 })
    }
  }

  countError = (type) => {
    if (type === 'block') {
      if (this.state.block.status) {
        this.checkStatusBlockQuickReply()
      } else {
        const count = this.state.countQuickReply
        this.setState({ countQuickReply: count + 1 })
        if (count + 1 >= 5) {
          this.setState({ block: { status: true, timestamp: dayjs() } })
          this.timer = setTimeout(() => {
            this.setState({ block: { status: false, timestamp: null }, countQuickReply: 0 })
          }, 600000)
          this.timer = setTimeout(() => {
            const group = this.state.message
            const errorMsg = this.getErrorMessage('block')
            const newGroup = group.concat([errorMsg])
            this.setState({ message: newGroup })
            this.sendMessageToSMM('blockQuickReply', errorMsg.messages[0].text)
          }, 10)
        }
        this.timer = setTimeout(() => {
          if (this.state.countQuickReply < 5) {
            this.setState({ countQuickReply: 0 })
          }
        }, 3000)
      }
    } else {
      return true
    }
  }

  isWaitingAgent = (val) => {
    if (val) {
      const messageWaitingTransfer = this.state.messageWaitingTransfer
      var countMessageWaitingTransfer = 0
      this.isWaitingAgentInterval = setInterval(() => {
        if (countMessageWaitingTransfer < messageWaitingTransfer.length) {
          const group = this.state.message
          const message = {
            messages: [{ type: 'text', text: messageWaitingTransfer[countMessageWaitingTransfer] }],
            position: 'receiver',
            userName: 'GOMO Customer Service',
            timestamp: new Date(),
          }
          const newGroup = group.concat([message])
          this.setState({ message: newGroup })
          this.sendMessageToSMM('waitingTransfer', messageWaitingTransfer[countMessageWaitingTransfer])
        } else return
        countMessageWaitingTransfer++
      }, 90000)
    } else {
      clearInterval(this.isWaitingAgentInterval)
    }
  }

  response = () => {
    socket.on(this.state.userId, (data) => {
      const decrypted = decryptedData(data, this.state.userId, this.state.userId)
      if (data && decrypted && !decrypted.error) {
        if (decrypted.profile.userProfile.intent === 'transfer') {
          if (this.state.isWaitingAgent) {
            this.isWaitingAgent(false)
          } else {
            this.setState({ isWaitingAgent: true, isTransfer: true })
            this.isWaitingAgent(true)
          }
        } else {
          this.setState({ isWaitingAgent: false, isTransfer: false })
        }
        const filter = decrypted.messages.filter((ele) => (ele.type === 'text' ? ele.text.trim() !== '' : ele))
        const filterSurveyCode = filter.find((ele) => ele.type === 'surveycsi')
        this.setState({
          surveyCode: filterSurveyCode?.surveyCode || null,
          surveyToken: filterSurveyCode?.token,
        })
        if (filter.length > 0) {
          if (
            filter[0].type === 'internetWithIM' ||
            filter[0].type === 'voiceWithIM' ||
            filter[0].type === 'kyc' ||
            filter[0].type === 'sendToBCWC'
          ) {
            this.setState({
              isActiveFormProblem: true,
              token: filter[0].token,
            })
            const newMessage = {
              ...decrypted,
              messages: [
                {
                  text: this.state.mobileNo
                    ? `คุณลูกค้า ต้องการทำรายการด้วยหมายเลข ${this.state.mobileNo} ใช่ไหมคะ`
                    : 'คุณลูกค้าช่วยพิมพ์หมายเลขโทรศัพท์ GOMO ที่ต้องการแจ้งปัญหาการใช้งานให้หน่อยนะคะ',
                  type: this.state.mobileNo ? 'condition' : 'text',
                },
              ],
              position: 'receiver',
              userName: 'GOMO Customer Service',
              timestamp: new Date(),
            }
            const group = this.state.message
            const newGroup = group.concat([newMessage])
            this.setState({
              message: newGroup,
              profile: decrypted.profile,
              showModalFormType: filter[0].type,
              msgGomoBcWc: filter[0].text,
            })
            this.scrollToBottom()
          } else {
            const newMessage = {
              ...decrypted,
              position: 'receiver',
              userName: 'GOMO Customer Service',
              timestamp: new Date(),
            }
            const group = this.state.message
            const newGroup = group.concat([newMessage])
            this.setState({ message: newGroup, profile: decrypted.profile })
            this.scrollToBottom()
          }
        } else {
          this.setState({ profile: decrypted.profile })
        }
      } else if (!this.state.block.status) {
        const group = this.state.message
        const errorMsg = this.getErrorMessage('default')
        const newGroup = group.concat([errorMsg])
        this.setState({ message: newGroup })
        this.scrollToBottom()
      }
    })
  }

  prepareMessage = (type, message) => {
    const { subChannel } = this.state
    switch (type) {
      case 'greeting':
        return {
          events: [
            {
              type: 'message',
              message: {
                type: 'text',
                text: message,
              },
              timestamp: new Date().getTime(),
              source: {
                type: 'user',
                userId: this.state.userId,
                mobileNo: this.state.mobileNo,
              },
            },
          ],
          from: 'greeting',
          subChannel: subChannel ? subChannel : isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb',
          channel: 'gomo',
          profile: { channel: 'gomo', userProfile: { ...this.state.profile?.userProfile, state_smm: 'new' } },
        }
      case 'transfer':
        return {
          events: [
            {
              type: 'message',
              message: {
                type: 'text',
                text: message,
              },
              timestamp: new Date().getTime(),
              source: {
                type: 'user',
                userId: this.state.userId,
                mobileNo: this.state.mobileNo,
              },
            },
          ],
          from: 'transfer',
          subChannel: subChannel ? subChannel : isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb',
          channel: 'gomo',
          profile: this.state.profile,
        }
      case 'waitingTransfer':
        return {
          events: [
            {
              type: 'message',
              message: {
                type: 'text',
                text: message,
              },
              timestamp: new Date().getTime(),
              source: {
                type: 'user',
                userId: this.state.userId,
                mobileNo: this.state.mobileNo,
              },
            },
          ],
          from: 'waitingTransfer',
          subChannel: subChannel ? subChannel : isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb',
          channel: 'gomo',
          profile: this.state.profile,
        }
      case 'blockQuickReply':
        return {
          events: [
            {
              type: 'message',
              message: {
                type: 'text',
                text: message,
              },
              timestamp: new Date().getTime(),
              source: {
                type: 'user',
                userId: this.state.userId,
                mobileNo: this.state.mobileNo,
              },
            },
          ],
          from: 'blockQuickReply',
          subChannel: subChannel ? subChannel : isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb',
          channel: 'gomo',
          profile: this.state.profile,
        }
      case 'problem':
        return {
          events: [
            {
              type: 'message',
              message: {
                type: 'text',
                text: message,
              },
              timestamp: new Date().getTime(),
              source: {
                type: 'user',
                userId: this.state.userId,
                mobileNo: this.state.mobileNo,
              },
            },
          ],
          from: 'problem',
          subChannel: subChannel ? subChannel : isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb',
          channel: 'gomo',
          profile: this.state.profile,
        }
      case 'messageNotSupport':
        this.setState({
          profile: {
            channel: 'gomo',
            userProfile: { ...this.state.profile?.userProfile, state: 'transfer', intent: 'transfer' },
          },
        })
        return {
          events: [
            {
              type: 'message',
              message: {
                type: 'text',
                text: message,
              },
              timestamp: new Date().getTime(),
              source: {
                type: 'user',
                userId: this.state.userId,
                mobileNo: this.state.mobileNo,
              },
            },
          ],
          from: 'messageNotSupport',
          subChannel: subChannel ? subChannel : isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb',
          channel: 'gomo',
          profile: {
            channel: 'gomo',
            userProfile: { ...this.state.profile?.userProfile, state: 'transfer', intent: 'transfer' },
          },
        }
      case 'messageNotSupportImageSize':
        return {
          events: [
            {
              type: 'message',
              message: {
                type: 'text',
                text: message,
              },
              timestamp: new Date().getTime(),
              source: {
                type: 'user',
                userId: this.state.userId,
                mobileNo: this.state.mobileNo,
              },
            },
          ],
          from: 'messageNotSupport',
          subChannel: subChannel ? subChannel : isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb',
          channel: 'gomo',
          profile: this.state.profile,
        }
      case 'message':
        return {
          events: [
            {
              type: 'message',
              message: {
                type: 'text',
                text: message,
              },
              timestamp: new Date().getTime(),
              source: {
                type: 'user',
                userId: this.state.userId,
                mobileNo: this.state.mobileNo,
              },
            },
          ],
          subChannel: subChannel ? subChannel : isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb',
          channel: 'gomo',
          profile: this.state.profile,
        }
      case 'postback':
        return {
          events: [
            {
              type: 'postback',
              postback: {
                data: message,
              },
              timestamp: new Date().getTime(),
              source: {
                type: 'user',
                userId: this.state.userId,
                mobileNo: this.state.mobileNo,
              },
            },
          ],
          subChannel: subChannel ? subChannel : isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb',
          channel: 'gomo',
          profile: this.state.profile,
        }
      case 'image':
        return {
          events: [
            {
              type: 'message',
              message: {
                type: 'image',
                text: message,
              },
              timestamp: new Date().getTime(),
              source: {
                type: 'user',
                userId: this.state.userId,
                mobileNo: this.state.mobileNo,
              },
            },
          ],
          subChannel: subChannel ? subChannel : isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb',
          channel: 'gomo',
          profile: this.state.profile,
        }
      case 'selfservice':
        return {
          events: [
            {
              type: 'message',
              message: {
                type: 'text',
                text: message,
              },
              timestamp: new Date().getTime(),
              source: {
                type: 'user',
                userId: this.state.userId,
                mobileNo: this.state.mobileNo,
              },
            },
          ],
          from: 'selfservice',
          subChannel: subChannel ? subChannel : isWebview(navigator.userAgent) ? 'gomoApp' : 'gomoWeb',
          channel: 'gomo',
          profile: this.state.profile,
        }
      default:
        break
    }
  }

  onSendMessage = async (value) => {
    if (this.state.isActiveFormProblem) {
      const newItem = {
        messages: [{ text: value.text.replace(/ /g, ''), type: 'text' }],
        position: 'sender',
        timestamp: new Date(),
      }
      this.setState({ message: [...this.state.message, newItem] })
      this.sendMessageToSMM('problem', value.text.replace(/ /g, ''))
      this.isRequireGomoNumber(value.text.replace(/ /g, ''))
    } else {
      if (value.quickReply) {
        this.countError('block')
      }
      const block = this.state.block.status
      if (value.text) {
        if (!value.quickReply || (!block && this.state.countQuickReply < 5)) {
          this.sendMessageToSMM('message', value.text)
        }
        const isStartDate = dayjs().format('DD/MM/YYYY') === this.state.date
        if (!isStartDate) {
          const newItem = { position: 'sectionDate', date: this.state.date }
          this.setState({ message: [...this.state.message, newItem], date: dayjs().format('DD/MM/YYYY') })
        }
        const newItem = { messages: [{ text: value.text, type: 'text' }], position: 'sender', timestamp: new Date() }
        this.setState({ message: [...this.state.message, newItem] })

        if (block && value.quickReply) {
          const errorMsg = this.getErrorMessage('block')
          this.setState({ message: this.state.message.concat([errorMsg]) })
        }
      }
    }
    this.timer = setTimeout(() => {
      this.scrollToBottom()
    }, 10)
  }

  encryptMobileNo = async (value) => {
    const data = {
      mobileNo: value,
    }
    const encrypt = await encryptMobileNo(this.state.token, data)
      .then((res) => {
        if (res.statusCode === '20000') {
          this.isRenderMessageOpenForm(res.data.emn)
        }
      })
      .catch((e) => {
        console.log(`"${JSON.stringify(e)}"`)
      })
    return encrypt
  }

  isRenderMessageOpenForm = async (mbtoken) => {
    switch (this.state.showModalFormType) {
      case 'kyc':
        const newItemKyc = {
          messages: [
            {
              template: {
                title: 'เข้าเว็บไซต์เพื่อแนบเอกสารให้ GOMO Agent',
                actions: [
                  {
                    text: 'กดที่นี่เลยค่ะ',
                    type: 'url',
                    url: [`${this.state.baseUrl}/v2/kyc?q=${this.state.token}&mbtoken=${mbtoken}`],
                  },
                ],
              },
              type: 'template',
            },
          ],
          position: 'receiver',
          userName: 'GOMO Customer Service',
          timestamp: new Date(),
        }
        this.setState({ message: [...this.state.message, newItemKyc], isActiveFormProblem: false })
        this.scrollToBottom()
        break
      case 'sendToBCWC':
        const newItemBcwc = {
          messages: [
            {
              template: {
                title: this.state.msgGomoBcWc,
                actions: [
                  {
                    text: 'กดที่นี่เลยค่ะ',
                    type: 'url',
                    url: [`${this.state.baseUrl}/v2/GOMO_BCWC?q=${this.state.token}&mbtoken=${mbtoken}`],
                  },
                ],
              },
              type: 'template',
            },
          ],
          position: 'receiver',
          userName: 'GOMO Customer Service',
          timestamp: new Date(),
        }
        this.setState({ message: [...this.state.message, newItemBcwc], isActiveFormProblem: false })
        this.scrollToBottom()
        break

      default:
        const newItem = {
          messages: [
            {
              template: {
                title: 'เพื่อตรวจสอบปัญหาการใช้งาน คุณลูกค้าช่วยระบุข้อมูลเพื่อส่งตรวจสอบให้หน่อยค่ะ',
                actions: [{ text: 'กดที่นี่เลยค่ะ', type: 'postback' }],
              },
              type: 'template',
            },
          ],
          position: 'receiver',
          userName: 'GOMO Customer Service',
          timestamp: new Date(),
        }
        this.setState({ message: [...this.state.message, newItem] })
        this.scrollToBottom()
        break
    }
  }

  isRequireGomoNumber = async (value) => {
    if (this.state.countCheckGomoNumber > 2) {
      const group = this.state.message
      const errorMsg = this.getErrorMessage('wrongGomoNumber')
      const newGroup = group.concat([errorMsg])
      this.setState({ message: newGroup, countCheckGomoNumber: 0, isActiveFormProblem: false })
      this.sendMessageToSMM('problem', errorMsg.messages[0].text)
    } else {
      const check = await this.checkGomoNumber(value)
      if (check) {
        this.setState({ countCheckGomoNumber: 0 })
        this.encryptMobileNo(value)
        this.scrollToBottom()
      } else {
        const newMessage = {
          messages: [
            {
              text: `คุณลูกค้าช่วยพิมพ์หมายเลขโทรศัพท์ GOMO ที่ต้องการแจ้งปัญหาการใช้งานให้หน่อยนะคะ`,
              type: 'text',
            },
          ],
          position: 'receiver',
          userName: 'GOMO Customer Service',
          timestamp: new Date(),
        }
        const group = this.state.message
        const newGroup = group.concat([newMessage])
        this.setState({ message: newGroup, countCheckGomoNumber: this.state.countCheckGomoNumber + 1 })
        this.sendMessageToSMM('problem', newMessage.messages[0].text)
      }
    }
  }

  onClickCondition = async (title) => {
    if (this.state.isActiveFormProblem && (title === 'ใช่' || title === 'ไม่ใช่')) {
      const newItem = { messages: [{ text: title, type: 'text' }], position: 'sender', timestamp: new Date() }
      this.setState({ message: [...this.state.message, newItem] })
      this.sendMessageToSMM('problem', title)
      if (title === 'ใช่') {
        const check = await this.checkGomoNumber(this.state.mobileNo)
        if (check) {
          this.encryptMobileNo(this.state.mobileNo)
        } else {
          this.isRequireGomoNumber()
        }
      } else if (title === 'ไม่ใช่') {
        this.isRequireGomoNumber()
      }
    } else {
      this.sendMessageToSMM('message', title)
      const isStartDate = dayjs().format('DD/MM/YYYY') === this.state.date
      if (!isStartDate) {
        const newItem = { position: 'sectionDate', date: this.state.date }
        this.setState({ message: [...this.state.message, newItem], date: dayjs().format('DD/MM/YYYY') })
      }
      const newItem = { messages: [{ text: title, type: 'text' }], position: 'sender', timestamp: new Date() }
      this.setState({ message: [...this.state.message, newItem] })
    }
  }

  onClickConditionSurvey = async (title) => {
    if (title === 'ครบ') {
      const filter = this.state.message.map((ele) => {
        const filterDisplay = ele.messages.filter((ele) => ele.type !== 'surveycsi')
        return {
          ...ele,
          messages: filterDisplay,
        }
      })
      this.setState({ message: filter, showModalSurvey: true })
    } else if (title === 'ไม่ครบ') {
      const newMessage = {
        messages: [
          {
            text: 'คุณลูกค้าต้องการให้เราดูแลเรื่องใดเพิ่มเติม พิมพ์เข้ามาได้เลยนะคะ เราจะรีบไปหาข้อมูลให้โดยเร็วที่สุดเลยค่ะ',
            type: 'text',
          },
        ],
        position: 'receiver',
        userName: 'GOMO Customer Service',
        timestamp: new Date(),
      }
      const group = this.state.message
      const newGroup = group.concat([newMessage])
      const filter = newGroup.map((ele) => {
        const filterDisplay = ele.messages.filter((ele) => ele.type !== 'surveycsi')
        return {
          ...ele,
          messages: filterDisplay,
        }
      })
      this.setState({ message: filter, showModalSurvey: false })
      this.sendMessageToSMM('selfservice', newMessage?.messages[0].text)
    }
  }

  onClickDialog = (payload, title) => {
    this.checkStatusBlockQuickReply()
    if (payload && title) {
      this.sendMessageToSMM('postback', payload)
      const isStartDate = dayjs().format('DD/MM/YYYY') === this.state.date
      if (!isStartDate) {
        const newItem = { position: 'sectionDate', date: this.state.date }
        this.setState({ message: [...this.state.message, newItem], date: dayjs().format('DD/MM/YYYY') })
      }
      const newItem = { messages: [{ text: title, type: 'text' }], position: 'sender', timestamp: new Date() }
      this.setState({ message: [...this.state.message, newItem] })
    }
    this.timer = setTimeout(() => {
      this.scrollToBottom()
    }, 10)
  }

  renderMessageContainer = () => {
    const renderMessageContainer = this.state.message.map((ele, i) => {
      const isChoice = ele.messages.some((ele) => ele.type === 'choice')
      if (ele.position === 'receiver' && isChoice) {
        return <MessageDialog key={i} data={ele} fixed={this.props.fixed} onClickDialog={this.onClickDialog} />
      }

      switch (ele.position) {
        case 'receiver':
          return (
            <MessageWrapReceiver key={i} data={ele} fixed={this.props.fixed}>
              {this.renderMessage(ele.messages, 'receiver', i)}
            </MessageWrapReceiver>
          )
        case 'sender':
          return (
            <MessageWrapSender key={i} data={ele} fixed={this.props.fixed}>
              {this.renderMessage(ele.messages, 'sender', i)}
            </MessageWrapSender>
          )
        case 'sectionDate':
          return <SectionLine date={ele.date} fixed={this.props.fixed} />
        default:
          return null
      }
    })
    return (
      <>
        {this.state.message.length > 0 && renderMessageContainer}
        <div ref={this.messagesEndRef} />
      </>
    )
  }

  onOpenPdf = (data) => {
    this.setState({ openPdf: true, pdfData: data })
  }

  renderMessage = (data, type, position) => {
    const renderMessageReciever = data.map((ele, i) => {
      switch (ele.type) {
        case 'text':
          return <MessageTextReceiver key={i} data={ele} fixed={this.props.fixed} onSendMessage={this.onSendMessage} />
        case 'choice':
          return <MessageDialog key={i} data={ele} fixed={this.props.fixed} onClickDialog={this.onClickDialog} />
        case 'template':
          return (
            <MessageTemplate
              onOpenPdf={this.onOpenPdf}
              key={i}
              data={ele}
              fixed={this.props.fixed}
              onClickDialog={
                this.state.isActiveFormProblem && this.state.showModalFormType !== 'kyc'
                  ? this.onOpenForm
                  : this.onClickDialog
              }
            />
          )
        case 'image':
          return <MessageImage key={i} data={ele} fixed={this.props.fixed} />
        case 'condition':
          return (
            <MessageCondition
              active={this.state.message.length - 1 === position}
              key={i}
              data={ele}
              fixed={this.props.fixed}
              onClickDialog={this.onClickCondition}
            />
          )
        case 'surveycsi':
          return (
            <MessageConditionSurvey
              key={i}
              text={'คุณลูกค้าได้รับข้อมูลจาก GOMO ครบถ้วนหรือไม่คะ'}
              fixed={this.props.fixed}
              onClickDialog={this.onClickConditionSurvey}
            />
          )
        default:
          return null
      }
    })

    const renderMessageSender = data.map((ele, i) => {
      switch (ele.type) {
        case 'text':
          return <MessageTextSender key={i} data={ele} fixed={this.props.fixed} />
        case 'loadingImage':
          return <MessageTextSenderLoadingImage key={i} data={ele} fixed={this.props.fixed} />
        case 'image':
          return <MessageImageSender key={i} data={ele} fixed={this.props.fixed} />
        default:
          return null
      }
    })

    return <>{type === 'receiver' ? renderMessageReciever : renderMessageSender}</>
  }

  setMobileNo = async (value) => {
    if (value.length === 10) {
      this.setState({ mobileNo: value, showModalGomo: false })
    }
  }

  closeModal = (type) => {
    document.body.className = document.body.className.replace('gomo-overflow-hiden', '')
    if (type === 'form') {
      const newMessage = {
        messages: [{ text: 'ยกเลิกการทำรายการนะคะ ต้องการสอบถามข้อมูลเรื่องอื่นพิมพ์บอกเราได้เลยนะคะ', type: 'text' }],
        position: 'receiver',
        userName: 'GOMO Customer Service',
        timestamp: new Date(),
      }
      const group = this.state.message
      const newGroup = group.concat([newMessage])
      this.setState({ message: newGroup, showModalForm: false, isActiveFormProblem: false })
      this.sendMessageToSMM('problem', newMessage.messages[0].text)
    } else {
      this.setState({ showModalGomo: false, openPdf: false, pdfData: null })
    }
  }

  closeModalSurvey = (type, survey) => {
    document.body.className = document.body.className.replace('gomo-overflow-hiden', '')
    if (type === 'success') {
      let listMessage = []
      if (survey?.thxData?.pic) {
        listMessage.push({
          text: `<img src=${survey?.thxData?.pic} style="max-width: 100%;height: auto;max-height: 250px;"></img>`,
          type: 'image',
        })
      }
      if (survey?.thxData?.text) {
        listMessage.push({
          text:
            survey.thxData.text ||
            'ขอบคุณคุณลูกค้าที่ช่วยประเมินการให้บริการของ GOMO ช่วยเป็นกำลังใจให้กันต่อไปนะคะ ครั้งหน้าอย่าลืมมาสมัครแพ็ก หรือถามข้อมูล กับ GOMO ได้ตลอด 24 ชั่วโมงค่ะ',
          type: 'text',
        })
      } else {
        listMessage.push({
          text: 'ขอบคุณคุณลูกค้าที่ช่วยประเมินการให้บริการของ GOMO ช่วยเป็นกำลังใจให้กันต่อไปนะคะ ครั้งหน้าอย่าลืมมาสมัครแพ็ก หรือถามข้อมูล กับ GOMO ได้ตลอด 24 ชั่วโมงค่ะ',
          type: 'text',
        })
      }
      const newMessage = {
        messages: listMessage,
        position: 'receiver',
        userName: 'GOMO Customer Service',
        timestamp: new Date(),
      }
      const group = this.state.message
      const newGroup = group.concat([newMessage])
      this.setState({ message: newGroup, showModalSurvey: false, surveyCode: null, surveyToken: null })
      this.sendMessageToSMM('selfservice', newMessage.messages[0].text)
    } else {
      const newMessage = {
        messages: [{ text: 'เกิดข้อผิดพลาดบางประการ', type: 'text' }],
        position: 'receiver',
        userName: 'GOMO Customer Service',
        timestamp: new Date(),
      }
      const group = this.state.message
      const newGroup = group.concat([newMessage])
      this.setState({ message: newGroup, showModalSurvey: false, surveyCode: null, surveyToken: null })
      this.sendMessageToSMM('selfservice', newMessage.messages[0].text)
    }
  }

  checkGomoNumber = (value) => {
    const body = {
      mobileNo: value,
      userId: this.state.userId,
    }
    const check = checkProfileType(body)
      .then((res) => {
        if (res.statusCode === '20000' && res.data.isGomo === true) {
          this.setState({ mobileNo: value })
          return true
        } else {
          this.setState({ mobileNo: null })
          return false
        }
      })
      .catch((e) => {
        return false
      })
    return check
  }

  onOpenForm = () => {
    this.setState({ showModalForm: true })
  }

  onSubmitForm = (data) => {
    document.body.className = document.body.className.replace('gomo-overflow-hiden', '')
    const newMessage = {
      messages: data,
      position: 'receiver',
      userName: 'GOMO Customer Service',
      timestamp: new Date(),
    }
    const group = this.state.message
    const newGroup = group.concat([newMessage])
    this.setState({ message: newGroup, showModalForm: false, isActiveFormProblem: false })
    this.sendMessageToSMM('problem', data[0].text)
  }

  sendMessageToSMM = (type, value) => {
    const prepareMessage = this.prepareMessage(type, value)
    const encryptData = encryptedData(prepareMessage, this.state.userId, this.state.userId)
    socket.emit(`chat-gomo-${this.state.userId}`, encryptData)
    this.scrollToBottom()
  }

  onOpenChat = () => {
    if (!this.state.userId) {
      this.getUserId()
      this.setState({ openChat: true })
    } else {
      this.setState({ openChat: true })
    }
  }

  onUploadFile = async (oldFile) => {
    const fileSize = oldFile?.size / 1000 / 1000
    const typeName = oldFile.name.split('.')
    const fileType = typeName[typeName.length - 1]
    const file = new File([oldFile], oldFile.name, {
      type: oldFile.type ? oldFile.type : `image/${fileType.toLowerCase()}`,
      mimetype: `image/${fileType.toLowerCase()}`,
    })
    if (fileSize > 10) {
      const group = this.state.message
      const errorMsg = this.getErrorMessage('imageOverSize')
      const newGroup = group.concat([errorMsg])
      this.setState({ message: newGroup })
      this.sendMessageToSMM('messageNotSupportImageSize', errorMsg.messages[0].text)
    } else if (
      !['image/jpeg', 'image/jpg', 'image/png'].includes(file.type.toLowerCase()) &&
      !['jpeg', 'jpg', 'png'].includes(fileType.toLowerCase())
    ) {
      const group = this.state.message
      const errorMsg = this.getErrorMessage('imageWrongType')
      const newGroup = group.concat([errorMsg])
      this.setState({ message: newGroup })
      this.sendMessageToSMM('messageNotSupportImageSize', errorMsg.messages[0].text)
    } else {
      this.onShowLoadingSendImage()
      const options = {
        initialQuality: 0.5,
        useWebWorker: true,
      }
      const compressedFile = await imageCompression(file, options)
      const newFile = new File([compressedFile], compressedFile.name, {
        type: compressedFile.type,
        mimetype: compressedFile.type,
      })
      const formData = new FormData()
      formData.append('file', newFile)
      uploadFile(this.state.userId, formData)
        .then((res) => {
          if (res.statusCode === '20000') {
            this.onHideLoadingSendImage()
            this.onPushImageMessage(res.data)
          } else {
            this.onHideLoadingSendImage()
          }
        })
        .catch((e) => {
          this.onHideLoadingSendImage()
          console.log(`"${JSON.stringify(e)}"`)
        })
    }
  }

  onShowLoadingSendImage = () => {
    this.setState({ loadingUploadFile: true })
    const newItem = {
      messages: [{ text: 'รอสักครู่ ระบบกำลังดำเนินการให้คุณ', type: 'loadingImage' }],
      position: 'sender',
      timestamp: new Date(),
      type: 'loadingImage',
    }
    this.setState({ message: [...this.state.message, newItem] })
    this.timer = setTimeout(() => {
      this.scrollToBottom()
    }, 10)
  }

  onHideLoadingSendImage = () => {
    this.setState({ loadingUploadFile: false })
    const filter = this.state.message.filter((ele) => ele.type !== 'loadingImage')
    this.setState({ message: filter })
  }

  onPushImageMessage = (file) => {
    const newItem = { messages: file, position: 'sender', timestamp: new Date() }
    this.setState({ message: [...this.state.message, newItem] })
    this.sendMessageToSMM('image', file[0].text)
  }

  render() {
    return (
      <StyledContainer className="gomo-widgets">
        {this.props.fixed && !this.state.openChat ? (
          <StyledWrapWidget onClick={() => this.onOpenChat()}>
            <Image preview={false} src={getImageSrc('image/gomo-widget.png')} />
          </StyledWrapWidget>
        ) : (
          <>
            {this.state.openPdf && <PDF url={this.state.pdfData} closeModal={this.closeModal} />}
            {this.state.showModalGomo && <ModalGomo closeModal={this.closeModal} onSubmit={this.setMobileNo} />}
            {this.state.showModalSurvey && this.state.surveyCode && (
              <SurveyForm
                closeModalSurvey={this.closeModalSurvey}
                type="modal"
                userId={this.state.userId}
                surveyCode={this.state.surveyCode}
                profile={this.state.profile}
                token={this.state.surveyToken}
              />
            )}
            {this.state.showModalForm && (
              <StyledWrapForm>
                <StyledModal>
                  {this.state.showModalFormType === 'internetWithIM' ? (
                    <InternetProblemForm
                      onSuccess={this.onSubmitForm}
                      type="modal"
                      mobileNo={this.state.mobileNo}
                      userId={this.state.userId}
                      closeModal={() => this.closeModal('form')}
                      token={this.state.token}
                    />
                  ) : (
                    <VoiceProblemForm
                      onSuccess={this.onSubmitForm}
                      type="modal"
                      mobileNo={this.state.mobileNo}
                      userId={this.state.userId}
                      closeModal={() => this.closeModal('form')}
                      token={this.state.token}
                    />
                  )}
                </StyledModal>
              </StyledWrapForm>
            )}
            <StyledWrapLiveChat className="scroll-wrap-widget" fixed={this.props.fixed}>
              <LiveChat
                loadingUploadFile={this.state.loadingUploadFile}
                onUploadFile={this.onUploadFile}
                checkStatusBlockQuickReply={this.checkStatusBlockQuickReply}
                blockQuickReply={this.state.block.status}
                showTopBar={this.state.showTopBar}
                width={this.props.width}
                height={this.props.height}
                fixed={this.props.fixed}
                goBack={() => {}}
                hideLiveChat={() => this.setState({ openChat: false })}
                headerText={'Customer Service'}
                onSendMessage={this.onSendMessage}
                suggestionItem={this.state.suggestionItem}
              >
                {this.renderMessageContainer()}
              </LiveChat>
            </StyledWrapLiveChat>
          </>
        )}
      </StyledContainer>
    )
  }
}
export default Gomo

export const StyledWrapLiveChat = styled.div`
  display: flex;
  right: 0px;
  bottom: 0px;
  position: fixed;
  justify-content: flex-end;
  align-items: flex-end;
  height: ${(props) => (props.fixed ? 'auto' : '100%')};
  width: ${(props) => (props.fixed ? 'auto' : '100%')};
  overflow-y: scroll;
  @media only screen and (max-width: 480px) {
    height: 100%;
    width: 100%;
  }
`

export const StyledWrapWidget = styled.div`
  display: flex;
  right: 20px;
  bottom: 20px;
  position: fixed;
  width: 100px;
`

export const StyledContainer = styled.div`
  z-index: 9999;
  display: flex;
`

export const StyledWrapForm = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 100;
  position: fixed;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  font-size: 22px;
  line-height: 1.3;
  overflow-wrap: anywhere;
  bottom: 0px;
`

export const StyledModal = styled.div`
  display: inline-block;
  overflow: auto;
  background-color: #fff;
  width: 40%;
  height: 80%;
  z-index: 100;
  position: absolute;
  border: 1px solid #e7279b;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 95%;
    height: 95%;
  }
`
