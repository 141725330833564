import React from 'react'

import {
  StyledWrapSuggestion,
  StyledSuggestionItem
} from './style'


export const Suggestion = (props) => (
  <>
  <div className="scroll-wrap-widget-suggestion">
  <StyledWrapSuggestion>
    {props?.data?.map((ele, i) => {
         return (
          <StyledSuggestionItem blockQuickReply={props.blockQuickReply} onClick={() => !props.blockQuickReply && props.onClick(ele)} key={i}>
            {ele.text}
          </StyledSuggestionItem>
        )
        })}
    </StyledWrapSuggestion>
    </div>
  </>
)
