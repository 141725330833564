import React, { Component } from 'react'
import { Document, Page } from '@react-pdf/renderer'
import { StyledWrapModal, StyledClose } from './style'

import { pdfjs } from 'react-pdf';
// (async function () { 
//  pdfjs = await import("pdfjs-dist/build/pdf.mjs"); 
//  const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.mjs");
//   pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker; 
// })();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default class PDF extends Component {
  constructor(props) {
    super(props)
    this.state = {
      url: '',
      page: [],
    }
  }

  componentDidMount = () => {
    if (this.props.url) {
      this.setState({ url: this.props.url })
    }
  }

  onDocumentLoadSuccess = (pdf) => {
    let list = []
    for (let index = 0; index < pdf?.numPages; index++) {
      list.push(index + 1)
    }
    this.setState({ page: list })
  }

  renderPage = () => {
    const page = this.state.page.map((ele) => {
      return <Page pageNumber={ele} />
    })
    return page
  }

  render() {
    return (
      <StyledWrapModal>
        <StyledClose onClick={this.props.closeModal}>X Close</StyledClose>
        <Document file={this.state.url} onLoadSuccess={this.onDocumentLoadSuccess}>
      {this.state.page.length > 0 && this.renderPage()}
        </Document>
      </StyledWrapModal>
    )
  }
}
