import api from './api'

export const getGreeting = async () => {
  return await api.post(`/api/gomo/greeting/message`, { action: 'getUserChat' })
}

export const checkProfileType = async (data) => {
  return await api.post(`/api/gomo/self-service/profile-type`, data)
}

export const uploadFile = async (id, formData) => {
  return await api.post(`/api/gomo/manage-file/upload/${id}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}

export const encryptMobileNo = async (token, data) => {
  return await api.post(`/api/gomo/check/emn`, data, {
    headers: {
      'content-type': 'application/json',
      Authorization: token,
    },
  })
}

export const decryptMobileNo = async (token, data) => {
  return await api.post(`/api/gomo/check/dmn`, data, {
    headers: {
      'content-type': 'application/json',
      Authorization: token,
    },
  })
}

export const decryptMessage = async (data) => {
  return await api.post(`/api/gomo/check/dqs`, data)
}
