import crypto from 'crypto'

export const encryptedData = (message, initVector, securitykey) => {
  message = JSON.stringify(message)
  initVector = initVector.slice(0, 16)
  securitykey = securitykey.slice(0, 32)
  if (initVector.length !== 16 || securitykey.length !== 32) return false
  const algorithm = 'aes-256-cbc'
  const cipher = crypto.createCipheriv(algorithm, securitykey, initVector)
  let encrypted = cipher.update(message, 'utf-8', 'hex')
  encrypted += cipher.final('hex')
  return encrypted
}

export const decryptedData = (encrypted, initVector, securitykey) => {
  initVector = initVector.slice(0, 16)
  securitykey = securitykey.slice(0, 32)
  if (initVector.length !== 16 || securitykey.length !== 32) return false
  const algorithm = 'aes-256-cbc'
  const decipher = crypto.createDecipheriv(algorithm, securitykey, initVector)
  let decrypted = decipher.update(encrypted, 'hex', 'utf8');
decrypted += decipher.final('utf8');
  decrypted = JSON.parse(decrypted)
  return decrypted
}
