import React, { Component } from 'react'

import InternetProblemFormModal from './internetProblemFormModal'
import InternetProblemFormWebview from './internetProblemFormWebview'

import { Spin, Modal } from 'antd'
import { LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { getCustomerIm, getLocation, createInternetProblem } from '../../services/selfService'
import { baseUrl } from '../../functions/baseUrl'
const dayjs = require('dayjs')
export default class InternetProblemForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userId: this.props.userId || '',
      mobileNo: this.props.mobileNo || '',
      token: this.props.token || '',
      form: {
        problemMessage: '',
        websiteProblem: '',
        addressTumbol: '',
        addressAmphur: '',
        addressProvince: '',
        addressPostCode: '',
        callBackNumber: this.props.mobileNo || '',
      },
      loading: false,
      showModal: false,
      message: { text: '', success: false },
      provinceList: [],
      customerIm: null,
      baseUrl: baseUrl(),
    }
    this.formRef = React.createRef()
  }

  componentDidMount = () => {
    this.getCustomerIm()
    this.formRef.current.setFieldsValue({
      callBackNumber: this.props.mobileNo || '',
    })
  }

  getCustomerIm = () => {
    const body = {
      mobileNo: this.props.mobileNo,
      userId: this.props.userId,
      type: 'internet',
    }
    getCustomerIm(body)
      .then((res) => {
        if (res.statusCode === '20000') {
          this.setState({ customerIm: res.data })
        }
      })
      .catch((e) => {
        console.log(`"${JSON.stringify(e)}"`)
      })
  }

  onSelectValue = (data) => {
    if (this.state.customerIm) {
      const filter = this.state.customerIm.find((ele) => ele.problemMessage === data)
      if (!filter) return
      this.setState({
        addressAmphur: filter.addressAmphur || null,
        addressPostCode: filter.addressPostCode || null,
        addressProvince: filter.addressProvince || null,
        addressTumbol: filter.addressTumbol || null,
      })
      this.formRef.current.setFieldsValue({
        addressAmphur: filter.addressAmphur || null,
        addressBuilding: filter.addressBuilding || null,
        addressFloor: filter.addressFloor || null,
        addressMoo: filter.addressMoo || null,
        addressMooban: filter.addressMooban || null,
        addressNo: filter.addressNo || null,
        addressPostCode: filter.addressPostCode || null,
        addressProvince: filter.addressProvince || null,
        addressRoad: filter.addressRoad || null,
        addressSoi: filter.addressSoi || null,
        addressTumbol: filter.addressTumbol || null,
        problemMoreInfo: filter.problemMoreInfo || null,
        websiteProblem: filter.websiteProblem || null,
      })
    }
  }

  getLocation = (event) => {
    const body = {
      term: event,
      mobileNo: this.props.mobileNo,
      userId: this.props.userId,
    }
    getLocation(body)
      .then((res) => {
        if (res.statusCode === '20000') {
          this.setState({ provinceList: res.data })
        }
      })
      .catch((e) => {
        console.log(`"${JSON.stringify(e)}"`)
      })
  }

  renderProvinceList = (val1, val2, val3) => {
    const filterArray = this.state.provinceList.filter((ele) => ele[val1] === val2)
    const removeDuplicate = filterArray.filter(
      (value, index, self) => index === self.findIndex((t) => t[val3] === value[val3]),
    )
    return removeDuplicate
  }


  setSelect = (name) => (event) => {
    switch (name) {
      case 'addressProvince':
        this.getLocation(event)
        this.setState({
          form: {
            ...this.state.form,
            addressProvince: event,
            addressAmphur: '',
            addressTumbol: '',
            addressPostCode: '',
          },
        })
        this.formRef.current.setFieldsValue({
          addressProvince: event,
          addressAmphur: null,
          addressTumbol: null,
          addressPostCode: null,
        })
        break
      case 'addressAmphur':
        this.setState({
          form: {
            ...this.state.form,
            addressAmphur: event,
            addressTumbol: '',
            addressPostCode: '',
          },
        })
        this.formRef.current.setFieldsValue({
          addressAmphur: event,
          addressTumbol: null,
          addressPostCode: null,
        })
        break
      case 'addressTumbol':
        this.setState({
          form: {
            ...this.state.form,
            addressTumbol: event,
            addressPostCode: '',
          },
        })
        this.formRef.current.setFieldsValue({
          addressTumbol: event,
          addressPostCode: null,
        })
        break
      case 'addressPostCode':
        this.setState({
          form: {
            ...this.state.form,
            addressPostCode: event,
          },
        })
        this.formRef.current.setFieldsValue({
          addressPostCode: event,
        })
        break

      case 'problemMessage':
        this.onSelectValue(event)
        this.setState({
          form: {
            ...this.state.form,
            problemMessage: event,
          },
        })
        this.formRef.current.setFieldsValue({
          problemMessage: event,
        })
        break
      case 'websiteProblem':
        this.setState({
          form: {
            ...this.state.form,
            websiteProblem: event,
          },
        })
        this.formRef.current.setFieldsValue({
          websiteProblem: event,
        })
        break
      default:
        break
    }
  }



  onFinish = (values) => {
    this.setState({ loading: true })
    const date = values.time
      ? dayjs(`${dayjs(values.date).format('YYYY-MM-DD')} ${values.time}`).valueOf()
      : dayjs(values.date).valueOf()
    const websiteProblem = values.websiteProblem === 'อื่นๆ / Other' ? values.websiteProblemOther : values.websiteProblem
    const body = {
      mobileNo: this.props.mobileNo,
      userId: this.props.userId,
      problemDate: date || '',
      problemMessage: values.problemMessage || '',
      websiteProblem: websiteProblem || '',
      addressNo: values.addressNo || '',
      addressBuilding: values.addressBuilding || '',
      addressFloor: values.addressFloor || '',
      addressMoo: values.addressMoo || '',
      addressMooban: values.addressMooban || '',
      addressSoi: values.addressSoi || '',
      addressRoad: values.addressRoad || '',
      addressTumbol: values.addressTumbol || '',
      addressAmphur: values.addressAmphur || '',
      addressProvince: values.addressProvince || '',
      addressPostCode: values.addressPostCode || '',
      problemLandmark: values.problemLandmark || '',
      callBackNumber: values.callBackNumber || '',
      problemMoreInfo: values.problemMoreInfo || '',
    }
    createInternetProblem(this.state.token, body)
      .then((res) => {
        if (res.statusCode === '20000') {
          this.props.onSuccess(res.data)
          this.setState({ loading: false })
        } else {
          const message = [{
            type: 'text', text: res.data[0].text ||
              'ขออภัยไม่สามารถทำรายการได้ แนะนำให้เว้นช่วงและทำรายการแจ้งปัญหาใหม่อีกครั้งได้เลยนะคะ'
          }]
          this.props.onSuccess(message)
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        const message = [{ type: 'text', text: 'ขออภัยไม่สามารถทำรายการได้ แนะนำให้เว้นช่วงและทำรายการแจ้งปัญหาใหม่อีกครั้งได้เลยนะคะ' }]
        this.props.onSuccess(message)
        this.setState({ loading: false })
      })
  }

  onFinishWebview = (values) => {
    this.setState({ loading: true })
    const date = values.time
      ? dayjs(`${dayjs(values.date).format('YYYY-MM-DD')} ${values.time}`).valueOf()
      : dayjs(values.date).valueOf()
    const websiteProblem = values.websiteProblem === 'อื่นๆ / Other' ? values.websiteProblemOther : values.websiteProblem
    const body = {
      mobileNo: this.props.mobileNo,
      userId: this.props.userId,
      problemDate: date || '',
      problemMessage: values.problemMessage || '',
      websiteProblem: websiteProblem || '',
      addressNo: values.addressNo || '',
      addressBuilding: values.addressBuilding || '',
      addressFloor: values.addressFloor || '',
      addressMoo: values.addressMoo || '',
      addressMooban: values.addressMooban || '',
      addressSoi: values.addressSoi || '',
      addressRoad: values.addressRoad || '',
      addressTumbol: values.addressTumbol || '',
      addressAmphur: values.addressAmphur || '',
      addressProvince: values.addressProvince || '',
      addressPostCode: values.addressPostCode || '',
      problemLandmark: values.problemLandmark || '',
      callBackNumber: values.callBackNumber || '',
      problemMoreInfo: values.problemMoreInfo || '',
    }
    createInternetProblem(this.state.token, body)
      .then((res) => {
        if (res.statusCode === '20000') {
          this.setState({
            loading: false,
            message: { text: res.data[0].text, success: true },
            showModal: true,
            success: true,
          })
        } else {
          this.setState({
            loading: false,
            message: {
              text:
                res.data[0].text ||
                'ขออภัยไม่สามารถทำรายการได้ แนะนำให้เว้นช่วงและทำรายการแจ้งปัญหาใหม่อีกครั้งได้เลยนะคะ',
              success: false,
            },
            showModal: true,
          })
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          success: false,
          message: {
            text: 'ขออภัยไม่สามารถทำรายการได้ แนะนำให้เว้นช่วงและทำรายการแจ้งปัญหาใหม่อีกครั้งได้เลยนะคะ',
            success: false,
          },
          showModal: true,
        })
        this.setState({ loading: false })
      })
  }

  render() {
    const { form, provinceList, showModal, message } = this.state
    return (
      <>
        <Modal width={380} visible={showModal} centered footer={null} closable={false}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                fontSize: 30,
                color: '#403b89',
                alignItems: 'center',
              }}
            >
              <CheckCircleOutlined style={{ fontSize: 25, color: '#403b89', marginRight: '0.2em' }} />
              {message.success ? 'ทำรายการสำเร็จ' : 'ทำรายการล้มเหลว'}
            </div>
            <div
              style={{ width: '100%', display: 'flex', justifyContent: 'center', fontSize: 21, textAlign: 'center' }}
              dangerouslySetInnerHTML={{ __html: message.text || '' }}
            />
          </div>
        </Modal>
        <Spin
          spinning={this.state.loading}
          indicator={<LoadingOutlined style={{ fontSize: 50, color: '#403b89', marginTop: '4em' }} spin />}
        >
          {this.props.type === 'modal' ? (
            <InternetProblemFormModal
              closeModal={this.props.closeModal}
              mobileNo={this.props.mobileNo}
              userId={this.props.userId}
              formRef={this.formRef}
              onFinish={this.onFinish}
              setDatePicker={this.setDatePicker}
              setSelect={this.setSelect}
              setValue={this.setValue}
              getLocation={this.getLocation}
              provinceList={provinceList}
              form={form}
              renderProvinceList={this.renderProvinceList}
            />
          ) : (
            <InternetProblemFormWebview
              mobileNo={this.props.mobileNo}
              userId={this.props.userId}
              formRef={this.formRef}
              onFinish={this.onFinishWebview}
              setSelect={this.setSelect}
              getLocation={this.getLocation}
              provinceList={provinceList}
              form={form}
              renderProvinceList={this.renderProvinceList}
            />
          )}
        </Spin>
      </>
    )
  }
}
